import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // Utilise localStorage
import authReducer, { hydrate } from './authSlice';

const persistConfig = {
    key: 'root',
    storage, // Définit le type de stockage (ici localStorage)
};

const rootReducer = combineReducers({
    auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

// Détectez l'état restauré
persistor.subscribe(() => {
    if (persistor.getState().bootstrapped) {
        store.dispatch(hydrate()); // Marquez l'hydratation comme terminée
    }
});
export default store;
