/* eslint-disable no-unused-vars */
import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, Modal, Slide, TextField, Tooltip } from "@mui/material";
// import { useAuth } from "AuthProvider";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { config } from "constants/config/config";
import useDataFetching from "hooks/useDataFetching";
import usePostWithFile from "hooks/usePostWithFile";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Transition } from "../Employees/EmployeeRequest";
import { useSelector } from "react-redux";



function UpdatePermit({ contractId, open, onClose }) {
    const { t } = useTranslation();
    // const { user } = useAuth();
    const { user, token } = useSelector((state) => state.auth);
    const [motif, setMotif] = useState("");
    // const [description, setDescription] = useState("");
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [show, setShow] = useState(false);
    const [permit, setPermit] = useState("");
    const [profession, setProfession] = useState("");
    const [salary, setSalary] = useState(0);
    const [signdate, setDate] = useState("");
    const [indLogement, setIndLogement] = useState(0);
    const [indTransport, setIndTransport] = useState(0);
    const [indResponsabilite, setIndResponsabilite] = useState(0);
    const [indRepresentation, setIndRepresentation] = useState(0);
    const [primeExploitation, setPrimeExploitation] = useState(0);
    const [sursalaire, setSursalaire] = useState(0);
    const [primeSalissure, setPrimeSalissure] = useState(0);
    const [indCaisse, setIndCaisse] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [primePanier, setPrimePanier] = useState(0);
    const [billetAvion, setBilletAvion] = useState(0);
    const [autresPrimes, setAutresPrimes] = useState(0);
    // const [employe, setEmploye] = useState(null);
    const [errors, setErrors] = useState({});
    const [postDataWithFile, loadF, errorF, dataFile] = usePostWithFile(`${config.app.api_url}/user-requests/contracts`);

    console.log("🚀 ~ UpdatePermit ~ errorF:", errorF)
    // FETCH OCCUPATIONS LIST
    const [loadingP, errorP, occupations, fetchOccupations] = useDataFetching(
        `${config.app.api_url}/occupations`
    );
    console.log("🚀 ~ UpdatePermit ~ errorP:", errorP)

    // eslint-disable-next-line no-unused-vars
    const [loadingC, errorC, contract, fetchContract] = useDataFetching(
        `${config.app.api_url}/contracts/${contractId}`
    );


    useEffect(() => {
        fetchOccupations();
    }, []);
    useEffect(() => {
        fetchContract();
    }, []);

    useEffect(() => {
        if (contract?.data) {
            setSalary(contract?.data?.salary);
            setPermit(contract?.data?.permitNumber);
            setDate(contract?.data?.permitDate);
            setProfession(contract?.data?.occupation?.id);
            setIndLogement(contract?.data?.indLogement);
            setIndTransport(contract?.data?.indTransport);
            setIndResponsabilite(contract?.data?.indResponsabilite);
            setIndRepresentation(contract?.data?.indRepresentation);
            setPrimeExploitation(contract?.data?.primeExploitation);
            setSursalaire(contract?.data?.sursalaire);
            setPrimeSalissure(contract?.data?.primeSalissure);
            setIndCaisse(contract?.data?.indCaisse);
            setBonus(contract?.data?.bonus);
            setPrimePanier(contract?.data?.primePanier);
            setBilletAvion(contract?.data?.billetAvion);
            setAutresPrimes(contract?.data?.autresPrimes);


        }
    }, [contract]);




    const hasChanged = (oldData, newData) => oldData?.data?.[newData] != newData;

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        // if (!employe) newErrors.employe = t("Declaration.Employees.form.errors.employe");
        // if (!permit) newErrors.permit = t("Declaration.Employees.form.errors.permit");
        if (!salary) newErrors.salary = t("Declaration.Employees.form.errors.salary");
        // if (!signdate) newErrors.signdate = t("Declaration.Employees.form.errors.signdate");
        // if (!contract) newErrors.contract = t("Declaration.Employees.form.errors.contract");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            console.log('Form submitted');// Logique à exécuter si tous les champs sont remplis

            const formData = new FormData();
            //    contract?.data?.contractFile != formData.append('contractFile', contract);

            if (hasChanged(contract, 'salary')) formData.append('salary', salary || '');
            if (hasChanged(contract, 'permitNumber')) formData.append('permitNumber', permit || '');
            if (hasChanged(contract, 'permitDate')) formData.append('permitDate', signdate || '');
            if (hasChanged(contract, 'occupationName')) formData.append('occupationName', profession || '');
            if (hasChanged(contract, 'indLogement')) formData.append('indLogement', indLogement || '');
            if (hasChanged(contract, 'indTransport')) formData.append('indTransport', indTransport || '');
            if (hasChanged(contract, 'indResponsabilite')) formData.append('indResponsabilite', indResponsabilite || '');
            if (hasChanged(contract, 'indRepresentation')) formData.append('indRepresentation', indRepresentation || '');
            if (hasChanged(contract, 'primeExploitation')) formData.append('primeExploitation', primeExploitation || '');
            if (hasChanged(contract, 'sursalaire')) formData.append('sursalaire', sursalaire || '');
            if (hasChanged(contract, 'primeSalissure')) formData.append('primeSalissure', primeSalissure || '');
            if (hasChanged(contract, 'indCaisse')) formData.append('indCaisse', indCaisse || '');
            if (hasChanged(contract, 'bonus')) formData.append('bonus', bonus || '');
            if (hasChanged(contract, 'primePanier')) formData.append('primePanier', primePanier || '');
            if (hasChanged(contract, 'billetAvion')) formData.append('billetAvion', billetAvion || '');
            if (hasChanged(contract, 'autresPrimes')) formData.append('autresPrimes', autresPrimes || '');
            contractFile && formData.append('contractFile', contractFile);
            formData.append("motif", motif);
            formData.append("description", motif);
            formData.append("requestType", "UPDATE");
            formData.append("employerId", contract?.data?.employer.id);
            formData.append("userId", user.id);
            formData.append("contractId", contract?.data?.id);
            formData.append("attachedFiles", attachedFiles);

            await postDataWithFile(formData);
        }
    };



    useEffect(() => {
        console.log("DATA ", dataFile)
        if (dataFile && dataFile?.data?.id) {
            toast(dataFile?.message || t("success"))
        } else {
            // setErrorData(data?.message)
            toast(dataFile?.message || t("error"))
        }
    }, [dataFile])



    const [errorFile, setErrorFile] = useState("");
    const [contractFile, setContractFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.pdf"))
            } else if (file.size > maxSize) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.file"))
            } else {
                setErrorFile("")
                console.log("File ", file)
                setContractFile(file); // Si tout est correct, enregistrer le fichier
            }
        }
    };



    const [errorFiles, setErrorFiles] = useState("");

    const handleFilesChange = (e) => {
        const files = e.target.files;
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];
        let validFiles = [];
        let errors = [];

        if (files.length > 0) {
            // Parcourir tous les fichiers sélectionnés
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                // Vérifier le type du fichier
                if (!allowedTypes.includes(file.type)) {
                    errors.push(t("Register.errors.pdf")); // Ajouter un message d'erreur pour le type
                }
                // Vérifier la taille du fichier
                else if (file.size > maxSize) {
                    errors.push(t("Register.errors.file")); // Ajouter un message d'erreur pour la taille
                }
                // Si le fichier est valide
                else {
                    validFiles.push(file); // Ajouter le fichier valide à la liste
                }
            }

            if (errors.length > 0) {
                e.target.value = null; // Réinitialiser le champ de fichier si erreurs
                setErrorFiles(errors.join(", ")); // Afficher les messages d'erreur
            } else {
                setErrorFiles(""); // Réinitialiser les erreurs si tout est correct
                console.log("Valid Files: ", validFiles);
                setAttachedFiles(validFiles); // Enregistrer les fichiers valides dans l'état
            }
        }
    };



    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="lg"
            >
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">{t("Declaration.Permit.updateTitle")}</MKTypography>
                        <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShow(false)}>close</Icon>
                    </MKBox>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ p: 3 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid
                                container
                                item
                                xs={7}
                                lg={12}
                                md={7}
                                justifyContent="space-between" // Gérer l'espacement entre le texte et le bouton
                                alignItems="flex-end" // Aligner le bouton au fond (bas) à droite
                            >
                                {errorFile && <MKTypography
                                    variant="subtitle2"
                                    color="black"
                                    style={{ background: "yellow", mt: 2 }}
                                >
                                    {errorFile}
                                </MKTypography>}
                                {errorFiles && <MKTypography
                                    variant="subtitle2"
                                    color="black"
                                    style={{ background: "yellow", mt: 2 }}
                                >
                                    {errorFiles}
                                </MKTypography>}

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            required
                                            sx={{ width: "100%" }}
                                            value={motif}
                                            onChange={(e) => setMotif(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="text"
                                            label={t("Declaration.Employees.form.request.motif")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.motif}
                                            helperText={errors.motif}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <Tooltip title={t("Declaration.Employees.form.tooltip.file")} placement="left-start">
                                            <TextField
                                                // required
                                                sx={{ width: "100%" }}
                                                // value={contract}
                                                onChange={handleFilesChange}
                                                placeholder=""
                                                id="outlined-required"
                                                type="file"
                                                label={t("Declaration.Employees.form.request.file")}
                                                InputLabelProps={{ shrink: true }}
                                                error={!!errors.attachedFiles}
                                                helperText={errors.attachedFiles}
                                                inputProps={{ multiple: true }}
                                                accept="application/pdf"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            // value={contract}
                                            onChange={handleFileChange}
                                            placeholder=""
                                            id="outlined-required"
                                            type="file"
                                            label={t("Declaration.Permit.table.contract")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.contract}
                                            helperText={errors.contract}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={permit}
                                            onChange={(e) => setPermit(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="text"
                                            label={t("Declaration.Permit.table.numpermis")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.permit}
                                            helperText={errors.permit}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={signdate}
                                            onChange={(e) => setDate(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="date"
                                            label={t("Declaration.Permit.table.signdate")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.signdate}
                                            helperText={errors.signdate}
                                        />
                                    </Grid>
                                    {!loadingP &&
                                        <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                            <TextField
                                                required
                                                label={t("Declaration.Employees.form.profession")}
                                                id="outlined-required"
                                                select
                                                sx={{ width: "100%" }}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                value={profession}
                                                onChange={(e) => {
                                                    setProfession(e.target.value)
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                error={!!errors.profession}
                                                helperText={errors.profession}
                                            // InputProps={{
                                            //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                            // }}
                                            >
                                                <option>
                                                </option>
                                                {occupations?.data && occupations?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                    <option key={option.id} value={option.name}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>}
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            required
                                            sx={{ width: "100%" }}
                                            value={salary}
                                            onChange={(e) => setSalary(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.salary")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.salary}
                                            helperText={errors.salary}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={indLogement}
                                            onChange={(e) => setIndLogement(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.indLogement")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.indLogement}
                                            helperText={errors.indLogement}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={indTransport}
                                            onChange={(e) => setIndTransport(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.indTransport")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.indTransport}
                                            helperText={errors.indTransport}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={indResponsabilite}
                                            onChange={(e) => setIndResponsabilite(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.indResponsabilite")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.indResponsabilite}
                                            helperText={errors.indResponsabilite}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={indRepresentation}
                                            onChange={(e) => setIndRepresentation(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.indRepresentation")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.indRepresentation}
                                            helperText={errors.indRepresentation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={primeExploitation}
                                            onChange={(e) => setPrimeExploitation(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.primeExploitation")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.primeExploitation}
                                            helperText={errors.primeExploitation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={sursalaire}
                                            onChange={(e) => setSursalaire(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.sursalaire")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.sursalaire}
                                            helperText={errors.sursalaire}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={primeSalissure}
                                            onChange={(e) => setPrimeSalissure(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.primeSalissure")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.primeSalissure}
                                            helperText={errors.primeSalissure}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={indCaisse}
                                            onChange={(e) => setIndCaisse(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.indCaisse")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.indCaisse}
                                            helperText={errors.indCaisse}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={bonus}
                                            onChange={(e) => setBonus(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.bonus")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.bonus}
                                            helperText={errors.bonus}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={primePanier}
                                            onChange={(e) => setPrimePanier(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.primePanier")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.primePanier}
                                            helperText={errors.primePanier}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={billetAvion}
                                            onChange={(e) => setBilletAvion(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.billetAvion")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.billetAvion}
                                            helperText={errors.billetAvion}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                        <TextField
                                            sx={{ width: "100%" }}
                                            value={autresPrimes}
                                            onChange={(e) => setAutresPrimes(e.target.value)}
                                            placeholder=""
                                            id="outlined-required"
                                            type="number"
                                            label={t("Declaration.Permit.table.autresPrimes")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.autresPrimes}
                                            helperText={errors.autresPrimes}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>

                        {/* </Slide> */}
                        <DialogActions>
                            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                <MKBox display="flex" justifyContent="left" p={1.5}>
                                    <MKButton variant="contained" color="primary" onClick={handleSubmit}>
                                        {loadF ? t("Declaration.Employees.loading") : t("Declaration.Employees.add")}
                                    </MKButton>
                                </MKBox>
                                <MKBox display="flex" justifyContent="right" p={1.5}>
                                    <MKButton variant="gradient" color="dark" onClick={() => setShow(false)}>
                                        {t("FNTEC.table.enterprise.close")}
                                    </MKButton>
                                </MKBox>
                            </MKBox>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>


    );
}

// Ajouter la validation des props
UpdatePermit.propTypes = {
    open: PropTypes.bool.isRequired,   // 'value' peut être de n'importe quel type
    onClose: PropTypes.func.isRequired, // 'index' doit être un nombre
    contractId: PropTypes.number.isRequired
};

export default UpdatePermit;