import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Card, CardContent, Container, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { config } from "constants/config/config";
import usePost from "hooks/usePost";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MKBox from "components/MKBox";
// import { useAuth } from "AuthProvider";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";

function Account({ children, value, index, ...other }) {
    console.log(children)
    const { t } = useTranslation();
    // const { user } = useAuth();
    const { user } = useSelector((state) => state.auth);
    const [loading] = useState(false);
    const [password, setPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [ postData, load, error, data ] = usePost(`${config.app.api_url}/auth/reset-password`);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!password) newErrors.password = t("Profile.Account.errors.password");
        if (!newpassword) newErrors.newpassword = t("Profile.Account.errors.newpassword");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            await postData({ oldPassword: password, newPassword: newpassword, email:user?.email });
        }
    };

    useEffect(() => {
        console.log("DATA ", data)
        if (data && data?.data?.id) {
            // Si la requête a réussi et que nous avons un token, appeler login
            // login(data.user, data.access_token);
            toast(data?.message || t("success"))
        } else {
            toast(data?.message || t("error"))
            // setErrorData(data?.message)
        }
    }, [data])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Container>
                    <ToastContainer />
                        <Grid className="d-flex justify-content-center">
                            <Card style={{ background: "rgba(8, 56, 4, 0.72)", width: "150vh" }}>
                                <CardContent style={{ textAlign: "center" }}>
                                    {error && <MKTypography
                                        variant="subtitle2"
                                        color="white"
                                        style={{ background: "red", mt: 2 }}
                                    >
                                        {error}
                                    </MKTypography>}
                                    <Container>
                                        <form onSubmit={handleSubmit}>
                                            <MKBox>
                                                <TextField
                                                    required
                                                    disabled
                                                    sx={{
                                                        mt: 5,
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={user?.mail}
                                                    placeholder="example@example.com"
                                                    id="outlined-required"
                                                    type="email"
                                                    label={t("Login.card.email")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.email}
                                                    helperText={errors.email}
                                                />
                                                <TextField
                                                    required
                                                    sx={{
                                                        mt: 2,
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                    placeholder="*********************"
                                                    id="outlined-required"
                                                    type={showPassword ? 'text' : 'password'}
                                                    label={t("Profile.Account.card.password")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.password}
                                                    helperText={errors.password}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label={showPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    required
                                                    sx={{
                                                        mt: 2,
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={newpassword}
                                                    onChange={(e) => {
                                                        setNewPassword(e.target.value);
                                                    }}
                                                    placeholder="*********************"
                                                    id="outlined-required"
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    label={t("Profile.Account.card.newpassword")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.newpassword}
                                                    helperText={errors.newpassword}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label={showNewPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
                                                                    onClick={handleClickShowNewPassword}
                                                                    onMouseDown={handleMouseDownNewPassword}
                                                                    edge="end"
                                                                >
                                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <MKButton
                                                    disabled={loading}
                                                    variant="contained"
                                                    color="white"
                                                    size="large"
                                                    component="a"
                                                    onClick={handleSubmit}
                                                    sx={{ mt: 5, alignSelf: 'center', height: 'fit-content' }}
                                                >
                                                    {load ? t("Profile.Account.card.loading") : t("Profile.Account.card.update")}
                                                </MKButton>
                                            </MKBox>
                                        </form>
                                    </Container>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Container>
                </Box>
            )}
        </div>
    );
}

// Ajouter la validation des props
Account.propTypes = {
    children: PropTypes.string.isRequired, // 'text' doit être une chaîne de caractères
    value: PropTypes.any.isRequired,   // 'value' peut être de n'importe quel type
    index: PropTypes.number.isRequired // 'index' doit être un nombre
};

export default Account;