// import { useAuth } from "AuthProvider";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const { token, user, isHydrated } = useSelector((state) => state.auth);

  if (!isHydrated) {
    // Affichez un indicateur de chargement si l'état n'est pas encore restauré
    return <div>Chargement...</div>;
  }
  
  console.log("TOKEN CONN", token, user)
  if (!token) return <Navigate to="/login" />;
  return <Outlet />;
};

export default PrivateRoute;