import { Box, Button, CircularProgress, Container, Divider, Grid, Icon, IconButton, Menu, MenuItem, Modal, Slide, Table, TableContainer, TextField, Tooltip } from "@mui/material";
// import { useAuth } from "AuthProvider";
// import { useAuth } from "AuthProvider";
import axios from "axios";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { config } from "constants/config/config";
import dayjs from "dayjs";
import useDataFetching from "hooks/useDataFetching";
// import usePost from "hooks/usePost";
import usePostWithFile from "hooks/usePostWithFile";
// import usePostWithFile from "hooks/usePostWithFile";
import i18next from "i18next";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EmployeeRequest from "./EmployeeRequest";
import UpdatePermit from "../Permit/UpdatePermit";
import { useSelector } from "react-redux";
// import EmployeeRequest from "./EmployeeRequest";

function Employees({ children, value, index, ...other }) {
    const { t } = useTranslation();

    const [motif, setMotif] = useState("");
    const [description, setDescription] = useState("");
    const [requestType, setRequestType] = useState("");
    const [attachedFiles, setAttachedFiles] = useState([]);
    const { user, token } = useSelector((state) => state.auth);
    // const { user, token } = useAuth();
    const [employe, setEmploye] = useState(null);
    const [show, setShow] = useState(false);
    const [shows, setShows] = useState(false);
    const [showu, setShowu] = useState(false);
    const [showc, setShowc] = useState(false);
    const [showl, setShowl] = useState(false);
    const [noms, setNoms] = useState("");
    const [prenoms, setPrenoms] = useState("");
    const [nationality, setNationality] = useState("");
    const [profession, setProfession] = useState("");
    const [qualification, setQualification] = useState("");
    const [sexe, setSexe] = useState("");
    const [passeport, setPasseport] = useState("");
    const [visa, setVisa] = useState("");
    const [permit, setPermit] = useState("");
    const [emailEmp, setEmailEmp] = useState("");
    const [phoneEmp, setPhoneEmp] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [salary, setSalary] = useState(0);
    const [signdate, setDate] = useState("");
    const [hasPermit, setHasPermit] = useState("N");
    const [indLogement, setIndLogement] = useState(0);
    const [indTransport, setIndTransport] = useState(0);
    const [indResponsabilite, setIndResponsabilite] = useState(0);
    const [indRepresentation, setIndRepresentation] = useState(0);
    const [primeExploitation, setPrimeExploitation] = useState(0);
    const [sursalaire, setSursalaire] = useState(0);
    const [primeSalissure, setPrimeSalissure] = useState(0);
    const [indCaisse, setIndCaisse] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [primePanier, setPrimePanier] = useState(0);
    const [billetAvion, setBilletAvion] = useState(0);
    const [autresPrimes, setAutresPrimes] = useState(0);
    const [contract, setContract] = useState(null);
    console.log(children)
    const [errors, setErrors] = useState({});
    const [employees, setEmployees] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingS, setLoadingS] = useState(false);
    // const { postData, load, error, data } = usePost(`${config.app.api_url}/employers/declarations`);
    const [postDataWithFile, loadF, errorF, dataFile] = usePostWithFile(`${config.app.api_url}/employers/declarations`);
    const [postDataWithFileC, loadC, errorCo, dataC] = usePostWithFile(`${config.app.api_url}/contracts/declarations`);
    const [postDataWithFileR, loadR, errorR, dataR] = usePostWithFile(`${config.app.api_url}/user-requests`);
    const [postDataWithFileU, loadU, errorU, dataU] = usePostWithFile(`${config.app.api_url}/user-requests/employees`);

    const motifs = [
        { id: 1, name: t("Motif.1"), value: t("Motif.1") },
        { id: 2, name: t("Motif.2"), value: t("Motif.2") },
        { id: 3, name: t("Motif.3"), value: t("Motif.3") }
    ]

    // FETCH REGIONS LIST
    const [loadingC, errorC, countries, fetchCountries] = useDataFetching(
        `${config.app.api_url}/countries`
    );
    // FETCH OCCUPATIONS LIST
    const [loadingP, errorP, occupations, fetchOccupations] = useDataFetching(
        `${config.app.api_url}/occupations`
    );
    console.log("Error ", errorC, errorF, errorP, errorR, errorU, errorCo)

    useEffect(() => {
        fetchCountries();
        fetchOccupations();
    }, []);

    useEffect(() => {
        if (user) {
            console.log("USER ", user?.myEnterprise)
            showEmployees(user);
        }
    }, [user]);

    // function checkIsValidPermit(employe) {
    //   const contract = getLastContract(employe?.contracts);
    //   // console.log("🚀 ~ checkIsValidPermit ~ contract:", contract)
    //   // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", permitDate)
    //   return contract?.permitNumber != null && contract?.permitNumber != undefined && contract?.permitNumber?.length > 0 && new Date() < getExpiredPermitDate(contract?.permitDate)
    // }
    const [openUpdatePermit, setOpenUpdatePermit] = useState(false);
    const [selectedPermit, setSelectedPermit] = useState(null);

    const handleOPenUpdatePermit = (item) => {
        console.log("🚀 ~ handleOPenUpdatePermit ~ item:", item?.id)
        if (item) {
            setSelectedPermit(item?.id);
            setOpenUpdatePermit(true);
        }
    }

    function getLastContractPermitDate(employe) {
        const contract = getLastContract(employe?.contracts);

        if (contract != null && contract?.permitDate != null && contract?.permitDate != undefined) {
            // console.log("EXP ", getExpiredPermitDate(contract?.permitDate))
            return dayjs(getExpiredPermitDate(contract?.permitDate)).format("DD-MM-YYYY")
        }

        return t("FNTEC.table.employes.contract.notfound");
    }

    function getLastContractPermitDateObt(employe) {
        const contract = getLastContract(employe?.contracts);

        if (contract != null && contract?.permitDate != null && contract?.permitDate != undefined) {
            // console.log("EXP ", getExpiredPermitDate(contract?.permitDate))
            return dayjs(contract?.permitDate).format("DD-MM-YYYY")
        }

        return t("FNTEC.table.employes.contract.notfound");
    }

    function getLastContract(contracts) {
        if (!contracts || contracts?.length < 1) {
            return null
        }

        if (contracts?.length == 1) {
            return contracts[0];
        }

        return contracts?.sort((a, b) => new Date(b?.permitDate) - new Date(a?.permitDate))[0]

    }

    function getExpiredPermitDate(permitDate) {
        return permitDate ? new Date(new Date(permitDate).setFullYear(new Date(permitDate).getFullYear() + 2)) : null
    }

    function checkContractDate(employe) {
        const contract = getLastContract(employe?.contracts);

        if (contract != null && contract?.permitDate != null && contract?.permitDate != undefined) {
            return new Date() < getExpiredPermitDate(contract?.permitDate)
        }

        return false;
    }

    //FETCH EMPLOYEES BY ENTERPRISE ID
    const showEmployees = (user) => {
        if (!user) return
        setLoading(true);
        setEmployees([]);
        axios
            .get(`${config.app.api_url}/enterprises/${user?.myEnterprise?.id}/employers`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                console.log("EMPLOYEES", res);
                setEmployees(res?.data?.data);
            })
            .catch((err) => {
                console.log("EMPLOYEES ERR ", err);
                setEmployees([]);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    //FETCH EMPLOYEES BY ENTERPRISE ID
    const getContractByEmploye = (employe) => {
        if (!employe) return
        setLoadingS(true);
        setContracts([]);
        console.log("EMPLOYERS ", employe)
        axios
            .get(`${config.app.api_url}/employers/${employe?.id}/contracts`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                console.log("CONTRACTS ", res);
                setContracts(res?.data?.data);
            })
            .catch((err) => {
                console.log("CONTRACTS ERR ", err);
                setContracts([]);
            })
            .finally(() => {
                setLoadingS(false)
            });
    };


    //FORMATAGE DES SALAIRES
    const formatNumber = (nombre) => {

        if (nombre != null && nombre != undefined) {
            return nombre.toLocaleString('us')
        }
        else {
            return '--'
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!noms) newErrors.noms = t("Declaration.Employees.form.errors.noms");
        if (!prenoms) newErrors.prenoms = t("Declaration.Employees.form.errors.prenoms");
        if (!nationality) newErrors.nationality = t("Declaration.Employees.form.errors.nationality");
        // if (!profession) newErrors.profession = t("Declaration.Employees.form.errors.profession");
        if (!sexe) newErrors.sexe = t("Declaration.Employees.form.errors.sexe");
        if (!passeport) newErrors.passeport = t("Declaration.Employees.form.errors.passeport");
        if (hasPermit === "O" && !permit) newErrors.permit = t("Declaration.Employees.form.errors.permit");
        if (hasPermit === "O" && !salary) newErrors.salary = t("Declaration.Employees.form.errors.salary");
        if (hasPermit === "O" && !signdate) newErrors.signdate = t("Declaration.Employees.form.errors.signdate");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            console.log('Form submitted');// Logique à exécuter si tous les champs sont remplis

            // Envoi du fichier avec le FormData
            if (hasPermit === "N") {
                const formData = new FormData();
                formData.append('fullName', `${noms} ${prenoms}`);
                formData.append('firstName', `${prenoms}`);
                formData.append('lastName', `${noms}`);
                formData.append('nationality', nationality);
                formData.append('sexe', sexe);
                formData.append('passportNumber', passeport);
                formData.append('visaNumber', visa);
                formData.append('contract.salary', salary);
                formData.append('contract.occupationName', profession);
                formData.append('contract.indLogement', indLogement);
                formData.append('contract.indTransport', indTransport);
                formData.append('contract.indResponsabilite', indResponsabilite);
                formData.append('contract.indRepresentation', indRepresentation);
                formData.append('contract.primeExploitation', primeExploitation);
                formData.append('contract.sursalaire', sursalaire);
                formData.append('contract.primeSalissure', primeSalissure);
                formData.append('contract.indCaisse', indCaisse);
                formData.append('contract.bonus', bonus);
                formData.append('contract.primePanier', primePanier);
                formData.append('contract.billetAvion', billetAvion);
                formData.append('contract.autresPrimes', autresPrimes);
                formData.append('enterpriseId', user?.myEnterprise?.id);
                await postDataWithFile(formData);
            } else {
                const formData = new FormData();
                formData.append('contractFile', contract);
                formData.append('firstName', `${noms}`);
                formData.append('fullName', `${noms} ${prenoms}`);
                formData.append('lastName', `${prenoms}`);
                formData.append('nationality', nationality);
                formData.append('sexe', sexe);
                formData.append('passportNumber', passeport);
                formData.append('visaNumber', visa);
                formData.append('contract.permitNumber', permit);
                formData.append('contract.permitDate', signdate);
                formData.append('enterpriseId', user?.myEnterprise?.id);

                await postDataWithFile(formData);
            }
        }
    };

    const handleSubmitRequest = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!motif) newErrors.motif = t("Declaration.Employees.form.errors.motif");
        if (!description) newErrors.description = t("Declaration.Employees.form.errors.description");
        if (!requestType) newErrors.requestType = t("Declaration.Employees.form.errors.requestType");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            console.log('Form submitted');// Logique à exécuter si tous les champs sont remplis

            // Envoi du fichier avec le FormData
            const formData = new FormData();
            formData.append('userId', user?.id);
            formData.append('targetedEmployerId', employe?.id);
            formData.append('enterpriseId', user?.myEnterprise?.id);
            formData.append('motif', motif);
            formData.append('description', description);
            formData.append('requestEntityType', "EMPLOYEE");
            formData.append('requestType', requestType);
            formData.append("attachedFiles", attachedFiles);

            await postDataWithFileR(formData);
        }
    };

    const handleSubmitUpdate = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        // if (!motif) newErrors.motif = t("Declaration.Employees.form.errors.motif");
        // if (!description) newErrors.description = t("Declaration.Employees.form.errors.description");
        // if (!requestType) newErrors.requestType = t("Declaration.Employees.form.errors.requestType");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            console.log('Form submitted');// Logique à exécuter si tous les champs sont remplis

            // Envoi du fichier avec le FormData
            const formData = new FormData();
            formData.append('userId', user?.id);
            formData.append('employerId', employe?.id);
            formData.append('enterpriseId', user?.myEnterprise?.id);
            formData.append('motif', motif);
            formData.append('description', "RAS");
            formData.append('fullName', noms + " " + prenoms);
            formData.append('firstName', noms);
            formData.append('lastName', prenoms);
            formData.append('birthDate', birthDate);
            formData.append('phone', phoneEmp);
            formData.append('email', emailEmp);
            formData.append('qualification', qualification);
            formData.append('nationality', nationality);
            formData.append('passportNumber', passeport);
            formData.append('sexe', sexe);
            formData.append('requestType', "UPDATE");
            attachedFiles.forEach(element => {
                formData.append("attachedFiles", element);
            });

            await postDataWithFileU(formData);
        }
    };

    // useEffect(() => {
    //     console.log("DATA ", data)
    //     if (data && data?.data?.id) {
    //         toast.success(data?.message)
    //         setNoms("");
    //         setPrenoms("");
    //         setNationality("");
    //     } else {
    //         // setErrorData(data?.message)
    //         toast.error(data?.message)
    //     }
    // }, [data])

    useEffect(() => {
        console.log("DATA ", dataFile)
        if (dataFile && dataFile?.data?.id) {
            toast(dataFile?.message || t("success"))
            setNoms("");
            setPrenoms("");
            setNationality("");
            setSexe("");
            setPasseport("");
            setVisa("");
            setProfession("");
            setSalary("");
            setIndLogement("");
            setIndTransport("");
            setIndResponsabilite("");
            setIndRepresentation("");
            setPrimeExploitation("");
            setSursalaire("");
            setPrimeSalissure("");
            setIndCaisse("");
            setBonus("")
            setPrimePanier("");
            setBilletAvion("");
            setAutresPrimes("");
            setPermit("");
            setDate("");
            showEmployees(user);
            setEmploye(null);
            setShow(false)
        } else {
            // setErrorData(data?.message)
            toast(dataFile?.message || t("error"))
        }
    }, [dataFile])

    useEffect(() => {
        console.log("DATA ", dataR)
        if (dataR && dataR?.data?.id) {
            toast(dataR?.message || t("success"))
            setMotif("");
            setDescription("");
            setEmploye(null)
        } else {
            // setErrorData(data?.message)
            toast(dataR?.message || t("error"))
        }
    }, [dataR])

    useEffect(() => {
        console.log("DATA ", dataU)
        if (dataU && dataU?.data?.id) {
            toast(dataU?.message || t("success"))
            setNoms("");
            setPrenoms("");
            setMotif("");
            setDescription("");
            setNationality("");
            setSexe("");
            setPasseport("");
            setVisa("");
            setProfession("");
            setEmploye(null)
            setShowu(false)
            showEmployees(user);
        } else {
            // setErrorData(data?.message)
            toast(dataU?.message || t("error"))
        }
    }, [dataU])

    useEffect(() => {
        console.log("DATA ", dataC)
        if (dataC && dataC?.data?.id) {
            toast(dataC?.message || t("success"))
            setSalary("");
            setIndLogement("");
            setIndTransport("");
            setIndResponsabilite("");
            setIndRepresentation("");
            setPrimeExploitation("");
            setSursalaire("");
            setPrimeSalissure("");
            setIndCaisse("");
            setBonus("")
            setPrimePanier("");
            setBilletAvion("");
            setAutresPrimes("");
            setPermit("");
            setDate("");
            setEmploye(null);
            setShowc(false);
            showEmployees(user);
        } else {
            // setErrorData(data?.message)
            toast(dataC?.message || t("error"))
        }
    }, [dataC])

    const [errorFile, setErrorFile] = useState("");
    const [errorFiles, setErrorFiles] = useState("");

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.pdf"))
            } else if (file.size > maxSize) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.file"))
            } else {
                setErrorFile("")
                console.log("File ", file)
                setContract(file); // Si tout est correct, enregistrer le fichier
            }
        }
    };

    const handleFilesChange = (e) => {
        const files = e.target.files;
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];
        let validFiles = [];
        let errors = [];

        if (files.length > 0) {
            // Parcourir tous les fichiers sélectionnés
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                // Vérifier le type du fichier
                if (!allowedTypes.includes(file.type)) {
                    errors.push(t("Register.errors.pdf")); // Ajouter un message d'erreur pour le type
                }
                // Vérifier la taille du fichier
                else if (file.size > maxSize) {
                    errors.push(t("Register.errors.file")); // Ajouter un message d'erreur pour la taille
                }
                // Si le fichier est valide
                else {
                    validFiles.push(file); // Ajouter le fichier valide à la liste
                }
            }

            if (errors.length > 0) {
                e.target.value = null; // Réinitialiser le champ de fichier si erreurs
                setErrorFiles(errors.join(", ")); // Afficher les messages d'erreur
            } else {
                setErrorFiles(""); // Réinitialiser les erreurs si tout est correct
                console.log("Valid Files: ", validFiles);
                setAttachedFiles(validFiles); // Enregistrer les fichiers valides dans l'état
            }
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // useEffect(() => {
    //     setNationality("");
    //     setPasseport("");
    //     setSexe("");
    //     setSexe("");
    //     setVisa("");
    //     setPrenoms("");
    //     setNoms("");
    //     if (employe) {
    //         console.log("EMP ", employe);
    //         setNationality(employe?.nationality);
    //         setPasseport(employe?.passportNumber);
    //         setSexe(employe?.sexe);
    //         setSexe(employe?.sexe);
    //         setVisa(employe?.visaNumber);
    //         setPrenoms(employe?.firstName);
    //         setNoms(employe?.lastName);
    //     }
    // }, [employe])

    const handleSelectClick = (event, index) => {
        setAnchorEl(event?.currentTarget);
        console.log("INDEX ", index)
        const employee = employees.find((item, idx) => idx === index);
        console.log("INDEX EMP ", employee)
        getContractByEmploye(employee);
        setEmploye(employee);


        setNoms(employee?.firstName);
        setPrenoms(employee?.lastName);
        setNationality(employee?.nationality);
        setSexe(employee?.sexe);
        setPasseport(employee?.passportNumber);
        setVisa(employee?.visaNumber);
        setProfession(employee?.occupationName);
        setSalary(employee?.salary);
        setIndLogement(employee?.indLogement);
        setIndTransport(employee?.indTransport);
        setIndResponsabilite(employee?.indResponsabilite);
        setIndRepresentation(employee?.indRepresentation);
        setPrimeExploitation(employee?.primeExploitation);
        setSursalaire(employee?.sursalaire);
        setPrimeSalissure(employee?.primeSalissure);
        setIndCaisse(employee?.indCaisse);
        setBonus(employee?.bonus)
        setPrimePanier(employee?.primePanier);
        setBilletAvion(employee?.billetAvion);
        setAutresPrimes(employee?.autresPrimes);
        setPermit(employee?.permitNumber);
        setDate(employee?.permitDate);
        // const newEmployees = employees.map((emp, i) => {
        //     if (i === index) {
        //         return { ...emp, anchorEl: event.currentTarget, open: true };
        //     }
        //     return { ...emp, open: false };  // Fermer les autres menus
        // });
        // setEmployees(newEmployees);
    };


    function checkIsValidContractDate(permitDate) {
        return new Date() < getExpiredPermitDate(permitDate)
    }

    const handleSelectClose = (index) => {
        setAnchorEl(null);
        console.log("", index)
        // const newEmployees = employees.map((emp, i) => {
        //     if (i === index) {
        //         return { ...emp, open: false };
        //     }
        //     return emp;
        // });
        // setEmployees(newEmployees);
    };


    const handleSubmitPermit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!employe) newErrors.employe = t("Declaration.Employees.form.errors.employe");
        // if (!permit) newErrors.permit = t("Declaration.Employees.form.errors.permit");
        if (!salary) newErrors.salary = t("Declaration.Employees.form.errors.salary");
        // if (!signdate) newErrors.signdate = t("Declaration.Employees.form.errors.signdate");
        // if (!contract) newErrors.contract = t("Declaration.Employees.form.errors.contract");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            console.log('Form submitted');// Logique à exécuter si tous les champs sont remplis

            const formData = new FormData();
            formData.append('contractFile', contract);
            formData.append('salary', salary);
            formData.append('permitNumber', permit);
            formData.append('permitDate', signdate);
            formData.append('occupationName', profession);
            formData.append('indLogement', indLogement);
            formData.append('indTransport', indTransport);
            formData.append('indResponsabilite', indResponsabilite);
            formData.append('indRepresentation', indRepresentation);
            formData.append('primeExploitation', primeExploitation);
            formData.append('sursalaire', sursalaire);
            formData.append('primeSalissure', primeSalissure);
            formData.append('indCaisse', indCaisse);
            formData.append('bonus', bonus);
            formData.append('primePanier', primePanier);
            formData.append('billetAvion', billetAvion);
            formData.append('autresPrimes', autresPrimes);
            formData.append('bonus', bonus);
            formData.append('bonus', bonus);
            formData.append('employerId', employe?.id);

            await postDataWithFileC(formData);
        }
    };
    const [openRequest, setOpenRequest] = useState(false);
    console.log("🚀 ~ Employees ~ openRequest:", openRequest)
    const [selectedEmp, setSelectedEmp] = useState(null);
    console.log("🚀 ~ Employees ~ selectedEmp:", selectedEmp)
    return (
        <>
            {selectedPermit && <UpdatePermit contractId={selectedPermit} open={openUpdatePermit}
                onClose={() => { setOpenUpdatePermit(false); setSelectedPermit(null) }}

            />}
            {employe && <EmployeeRequest open={openRequest} onClose={() => { setOpenRequest(false); setEmploye(null) }} employer={employe} />}
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                <ToastContainer />
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Container>
                            <Grid
                                container
                                item
                                xs={7}
                                lg={12}
                                md={7}
                                justifyContent="space-between" // Gérer l'espacement entre le texte et le bouton
                                alignItems="flex-end" // Aligner le bouton au fond (bas) à droite
                            >
                                <MKTypography
                                    variant="h3"
                                    color="black"
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        '::after': {
                                            content: '""',
                                            position: 'absolute',
                                            bottom: 0,
                                            width: '50%',
                                            height: '3px',
                                            backgroundColor: '#FCE410',
                                            transform: 'translateX(-200%)',
                                        },
                                    }}
                                    mr={5}
                                >
                                    {t("Declaration.Employees.list")} ({!loading && employees ? employees.length : 0})
                                </MKTypography>

                                <MKButton
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    component="a"
                                    onClick={() => setShow(true)}
                                    sx={{ height: 'fit-content' }}
                                >
                                    {t("Declaration.Employees.add")}
                                </MKButton>
                            </Grid>

                        </Container>
                        <TableContainer>
                            {/* <Container>
                            <Grid container item justifyContent="center" alignItems={"center"} lg={12}>
                                {enterprises?.content && <TablePagination
                                    component="div"
                                    count={enterprises?.totalElements}
                                    page={page}
                                    onRowsPerPageChange={(event) => {
                                        // event.preventDefault();
                                        const value = parseInt(event.target.value, 10);
                                        console.log("VALUE ", value);
                                        setLimit(value);
                                        setPage(0);
                                    }}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={limit}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    labelRowsPerPage={t("numberPage")}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                                    lang={i18n.language}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                                />}
                            </Grid>
                        </Container> */}
                            <Table sx={{ mt: 2 }} responsive striped bordered hover size="sm" variant="light" className="custom-table">
                                <thead>
                                    <tr style={{ fontSize: "14px" }}>
                                        <th className="text-center" style={{
                                            color: "#0F056B",
                                            textTransform: "uppercase"
                                        }}>#</th>
                                        <th className="text-center" style={{
                                            color: "#0F056B",
                                            textTransform: "uppercase"
                                        }}>{t("FNTEC.table.employes.noms")}</th>
                                        <th className="text-center" style={{
                                            color: "#0F056B",
                                            textTransform: "uppercase"
                                        }}>{t("FNTEC.table.employes.sexe")}</th>
                                        {/* <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("FNTEC.table.employes.poste")}</th> */}
                                        {/* <th>Entreprise</th> */}
                                        {/* <th>Salaire</th> */}
                                        <th className="text-center" style={{
                                            color: "#0F056B",
                                            textTransform: "uppercase"
                                        }}>{t("FNTEC.table.employes.nationality")}</th>
                                        <th className="text-center" style={{
                                            color: "#0F056B",
                                            textTransform: "uppercase"
                                        }}>{t("FNTEC.table.employes.obtention")}</th>
                                        <th className="text-center" style={{
                                            color: "#0F056B",
                                            textTransform: "uppercase"
                                        }}>{t("FNTEC.table.employes.expire")}</th>
                                        <th className="text-center" style={{
                                            color: "#0F056B",
                                            textTransform: "uppercase"
                                        }}>{t("FNTEC.table.employes.statut")}</th>
                                        <th className="text-center" style={{
                                            color: "#0F056B",
                                            textTransform: "uppercase"
                                        }}>{t("FNTEC.table.employes.option")}</th>
                                    </tr>
                                </thead>
                                {loading && <CircularProgress />}
                                {!loading && <tbody>
                                    {employees && employees.map((item, index) => (
                                        <tr style={{
                                            color: "#0F056B",
                                            verticalAlign: "bottom",
                                            fontSize: "12px"
                                        }} key={index}>
                                            <td className="text-center" style={{ color: "#0F056B" }}>{index + 1}</td>
                                            <td style={{ color: "#0F056B" }}>{(item?.fullName?.toUpperCase() || item?.firstName?.toUpperCase() + " " + item?.lastName?.toUpperCase())}</td>
                                            <td className="text-center" style={{ color: "#0F056B" }}>{item?.sexe?.toUpperCase()}</td>
                                            {/* <td style={{ color: "#0F056B" }}>{item?.occupation?.name?.toUpperCase()}</td> */}
                                            {/* <td>{item?.enterprise?.socialRaison.toUpperCase()}</td> */}
                                            {/* <td>{formatNumber(item?.salary)}</td> */}
                                            <td className="text-center" style={{ color: "#0F056B" }}>{item?.nationality?.toUpperCase()}</td>
                                            <td className="text-center" style={{ color: "#0F056B" }}>{getLastContractPermitDateObt(item)}</td>
                                            <td className="text-center" style={{ color: "#0F056B" }}>{getLastContractPermitDate(item)}</td>
                                            <td className="text-center">{!checkContractDate(item) ?
                                                <Icon style={{ color: "#FF0000" }} fontSize="inherit">thumb_down</Icon>
                                                :
                                                <Icon style={{ color: "#008000" }} fontSize="inherit">thumb_up</Icon>}</td>
                                            <td className="text-center" style={{ color: "#0F056B" }}>
                                                <IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    aria-controls={open ? 'long-menu' : undefined}
                                                    aria-expanded={open ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleSelectClick(event, index)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={() => handleSelectClose(index)}
                                                    slotProps={{
                                                        paper: {
                                                            style: {
                                                                maxHeight: 48 * 4.5,
                                                                width: '20ch',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem onClick={() => {
                                                        // setEmploye(item);
                                                        setShowc(true);
                                                        handleSelectClose(index);
                                                    }}>
                                                        {t("Declaration.Employees.form.request.contract")}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        setShowl(true);
                                                        handleSelectClose(index);
                                                    }}>
                                                        {t("Declaration.Employees.listpermis")}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        // setEmploye(item);
                                                        setShowu(true);
                                                        handleSelectClose(index);
                                                    }}>
                                                        {t("Declaration.Employees.form.request.UPDATE")}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        // setEmploye(item);
                                                        setShows(true);
                                                        handleSelectClose(index);
                                                    }}>
                                                        {t("Declaration.Employees.request")}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        // setEmploye(item);
                                                        setOpenRequest(true);
                                                        setSelectedEmp(item);
                                                        handleSelectClose(index);
                                                    }}>
                                                        {t("Declaration.Employees.form.request.requestList")}
                                                    </MenuItem>
                                                </Menu>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>}
                            </Table>
                            {/* <Container>
                            <Grid container item justifyContent="center" lg={12}>
                                {enterprises?.content && <TablePagination
                                    component="div"
                                    count={enterprises?.totalElements}
                                    page={page}
                                    onRowsPerPageChange={(event) => {
                                        const value = parseInt(event.target.value, 10);
                                        console.log("VALUE ", value);
                                        setLimit(value);
                                        setPage(0);
                                    }}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={limit}
                                    labelRowsPerPage={t("numberPage")}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                                    lang={i18n.language}
                                    showFirstButton={true}
                                    showLastButton={true}
                                />}
                            </Grid>
                        </Container> */}
                        </TableContainer>

                        <Modal open={show} onClose={() => setShow(false)} sx={{ display: "grid", placeItems: "center" }}>
                            <Slide direction="down" in={show} timeout={500}>
                                <MKBox
                                    position="relative"
                                    width="80%"
                                    display="flex"
                                    flexDirection="column"
                                    borderRadius="xl"
                                    bgColor="white"
                                    shadow="xl"
                                >
                                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                        <MKTypography variant="h5">{t("Declaration.Employees.addTitle")}</MKTypography>
                                        <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShow(false)}>close</Icon>
                                    </MKBox>
                                    <Divider sx={{ my: 0 }} />
                                    <Container component="section" py={{ xs: 3 }} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                                        <form onSubmit={handleSubmit}>
                                            {errorFile && <MKTypography
                                                variant="subtitle2"
                                                color="black"
                                                style={{ background: "yellow", mt: 2 }}
                                            >
                                                {errorFile}
                                            </MKTypography>}
                                            <Grid container spacing={2} mt={2}> {/* container pour gérer l'espacement */}
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={noms}
                                                        onChange={(e) => setNoms(e.target.value)}
                                                        type="text"
                                                        placeholder="John"
                                                        id="outlined-required"
                                                        label={t("Declaration.Employees.form.noms")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.noms}
                                                        helperText={errors.noms}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={prenoms}
                                                        onChange={(e) => setPrenoms(e.target.value)}
                                                        placeholder="Doe"
                                                        id="outlined-required"
                                                        type="text"
                                                        label={t("Declaration.Employees.form.prenoms")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.prenoms}
                                                        helperText={errors.prenoms}
                                                    />
                                                </Grid>
                                                {!loadingC &&
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            required
                                                            label={t("Declaration.Employees.form.nationality")}
                                                            id="outlined-required"
                                                            select
                                                            sx={{ width: "100%" }}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={nationality}
                                                            onChange={(e) => {
                                                                setNationality(e.target.value)
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.nationality}
                                                            helperText={errors.nationality}
                                                        >
                                                            {countries?.data && countries?.data?.map((option) => (
                                                                <option key={option.id} value={i18next?.language == "fr" ? option?.nameFr : option?.nameEn}>
                                                                    {i18next?.language == "fr" ? option?.nameFr : option?.nameEn}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>}
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        label={t("Declaration.Employees.form.sexe")}
                                                        id="outlined-required"
                                                        select
                                                        sx={{ width: "100%" }}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        value={sexe}
                                                        onChange={(e) => {
                                                            setSexe(e.target.value)
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.sexe}
                                                        helperText={errors.sexe}
                                                    // InputProps={{
                                                    //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                                    // }}
                                                    >
                                                        <option key={t("M")} value={"M"}>
                                                            {t("Masculin")}
                                                        </option>
                                                        <option key={t("F")} value={"F"}>
                                                            {t("Feminin")}
                                                        </option>
                                                        {/* {departements?.data && departements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))} */}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={passeport}
                                                        onChange={(e) => setPasseport(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="text"
                                                        label={t("Declaration.Employees.form.passeport")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.passeport}
                                                        helperText={errors.passeport}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={visa}
                                                        onChange={(e) => setVisa(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="text"
                                                        label={t("Declaration.Employees.form.visa")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.visa}
                                                        helperText={errors.visa}
                                                    />
                                                </Grid>
                                                {!loadingP &&
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            required
                                                            label={t("Declaration.Employees.form.profession")}
                                                            id="outlined-required"
                                                            select
                                                            sx={{ width: "100%" }}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={profession}
                                                            onChange={(e) => {
                                                                setProfession(e.target.value)
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.profession}
                                                            helperText={errors.profession}
                                                        // InputProps={{
                                                        //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                                        // }}
                                                        >
                                                            <option>
                                                            </option>
                                                            {occupations?.data && occupations?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                <option key={option.id} value={option.name}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>}
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={salary}
                                                        onChange={(e) => setSalary(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        label={t("Declaration.Permit.table.salary")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.salary}
                                                        helperText={errors.salary}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indLogement}
                                                        onChange={(e) => setIndLogement(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.indLogement")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indLogement}
                                                        inputProps={{ min: 0 }}
                                                        helperText={errors.indLogement}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indTransport}
                                                        onChange={(e) => setIndTransport(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        label={t("Declaration.Permit.table.indTransport")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indTransport}
                                                        helperText={errors.indTransport}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indResponsabilite}
                                                        onChange={(e) => setIndResponsabilite(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        label={t("Declaration.Permit.table.indResponsabilite")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indResponsabilite}
                                                        helperText={errors.indResponsabilite}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indRepresentation}
                                                        onChange={(e) => setIndRepresentation(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        label={t("Declaration.Permit.table.indRepresentation")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indRepresentation}
                                                        helperText={errors.indRepresentation}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={primeExploitation}
                                                        onChange={(e) => setPrimeExploitation(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        label={t("Declaration.Permit.table.primeExploitation")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.primeExploitation}
                                                        helperText={errors.primeExploitation}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={sursalaire}
                                                        onChange={(e) => setSursalaire(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        inputProps={{ min: 0 }}
                                                        type="number"
                                                        label={t("Declaration.Permit.table.sursalaire")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.sursalaire}
                                                        helperText={errors.sursalaire}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={primeSalissure}
                                                        onChange={(e) => setPrimeSalissure(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        inputProps={{ min: 0 }}
                                                        type="number"
                                                        label={t("Declaration.Permit.table.primeSalissure")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.primeSalissure}
                                                        helperText={errors.primeSalissure}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        inputProps={{ min: 0 }}
                                                        value={indCaisse}
                                                        onChange={(e) => setIndCaisse(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.indCaisse")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indCaisse}
                                                        helperText={errors.indCaisse}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={bonus}
                                                        onChange={(e) => setBonus(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        inputProps={{ min: 0 }}
                                                        type="number"
                                                        label={t("Declaration.Permit.table.bonus")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.bonus}
                                                        helperText={errors.bonus}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={primePanier}
                                                        onChange={(e) => setPrimePanier(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        inputProps={{ min: 0 }}
                                                        type="number"
                                                        label={t("Declaration.Permit.table.primePanier")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.primePanier}
                                                        helperText={errors.primePanier}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={billetAvion}
                                                        onChange={(e) => setBilletAvion(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        inputProps={{ min: 0 }}
                                                        type="number"
                                                        label={t("Declaration.Permit.table.billetAvion")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.billetAvion}
                                                        helperText={errors.billetAvion}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={autresPrimes}
                                                        onChange={(e) => setAutresPrimes(e.target.value)}
                                                        placeholder=""
                                                        inputProps={{ min: 0 }}
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.autresPrimes")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.autresPrimes}
                                                        helperText={errors.autresPrimes}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <Tooltip title={t("Declaration.Employees.form.tooltip.workpermit")} placement="right-start">
                                                        <TextField
                                                            required
                                                            label={t("Declaration.Employees.form.workpermit")}
                                                            id="outlined-required"
                                                            select
                                                            sx={{ width: "100%" }}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={hasPermit}
                                                            onChange={(e) => {
                                                                setHasPermit(e.target.value)
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                        // InputProps={{
                                                        //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                                        // }}
                                                        >
                                                            <option key={t("O")} value={"O"}>
                                                                {t("yes")}
                                                            </option>
                                                            <option key={t("N")} value={"N"}>
                                                                {t("no")}
                                                            </option>
                                                            {/* {departements?.data && departements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))} */}
                                                        </TextField>
                                                    </Tooltip>
                                                </Grid>
                                                {hasPermit == "O" &&
                                                    <>
                                                        <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                            <Tooltip title={t("Declaration.Employees.form.tooltip.contract")} placement="left-start">
                                                                <TextField
                                                                    required
                                                                    sx={{ width: "100%" }}
                                                                    // value={contract}
                                                                    onChange={handleFileChange}
                                                                    placeholder=""
                                                                    id="outlined-required"
                                                                    type="file"
                                                                    label={t("Declaration.Permit.table.contract")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.contract}
                                                                    helperText={errors.contract}
                                                                />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                            <Tooltip title={t("Declaration.Employees.form.tooltip.permit")} placement="right-start">
                                                                <TextField
                                                                    required
                                                                    sx={{ width: "100%" }}
                                                                    value={permit}
                                                                    onChange={(e) => setPermit(e.target.value)}
                                                                    placeholder=""
                                                                    id="outlined-required"
                                                                    type="text"
                                                                    label={t("Declaration.Permit.table.numpermis")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.permit}
                                                                    helperText={errors.permit}
                                                                />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                            <Tooltip title={t("Declaration.Employees.form.tooltip.signdate")} placement="left-start">
                                                                <TextField
                                                                    required
                                                                    sx={{ width: "100%" }}
                                                                    value={signdate}
                                                                    onChange={(e) => setDate(e.target.value)}
                                                                    placeholder=""
                                                                    id="outlined-required"
                                                                    type="date"
                                                                    label={t("Declaration.Permit.table.signdate")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.signdate}
                                                                    helperText={errors.signdate}
                                                                />
                                                            </Tooltip>
                                                        </Grid>

                                                    </>
                                                }
                                            </Grid>
                                        </form>
                                    </Container>
                                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                        <MKBox display="flex" justifyContent="left" p={1.5}>
                                            <MKButton variant="gradient" color="dark" onClick={() => {
                                                showEmployees(user);
                                                setEmploye(null);
                                                setShow(false)
                                            }}>
                                                {t("FNTEC.table.enterprise.close")}
                                            </MKButton>
                                        </MKBox>
                                        <MKBox display="flex" justifyContent="right" p={1.5}>
                                            <MKButton component="a" disabled={loadF} variant="contained" color="primary" onClick={handleSubmit}>
                                                {loadF ? t("Declaration.Employees.loading") : t("Declaration.Employees.add")}
                                            </MKButton>
                                        </MKBox>
                                    </MKBox>
                                </MKBox>
                            </Slide>
                        </Modal>

                        <Modal open={shows} onClose={() => {
                            setEmploye(null)
                            setShows(false)
                        }} sx={{ display: "grid", placeItems: "center" }}>
                            <Slide direction="down" in={shows} timeout={500}>
                                <MKBox
                                    position="relative"
                                    width="80%"
                                    display="flex"
                                    flexDirection="column"
                                    borderRadius="xl"
                                    bgColor="white"
                                    shadow="xl"
                                >
                                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                        <MKTypography variant="h5">{t("Declaration.Employees.addRequest")} {employe && (employe?.fullName?.toUpperCase() || employe?.firstName?.toUpperCase() + " " + employe?.lastName?.toUpperCase())}</MKTypography>
                                        <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => {
                                            setEmploye(null)
                                            setShows(false)
                                        }}>close</Icon>
                                    </MKBox>
                                    <Divider sx={{ my: 0 }} />
                                    <Container component="section" py={{ xs: 3 }} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                                        <form onSubmit={handleSubmitRequest}>
                                            {errorFiles && <MKTypography
                                                variant="subtitle2"
                                                color="black"
                                                style={{ background: "yellow", mt: 2 }}
                                            >
                                                {errorFiles}
                                            </MKTypography>}
                                            <Grid container spacing={2} mt={2}> {/* container pour gérer l'espacement */}

                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        label={t("Declaration.Employees.form.request.requestType")}
                                                        id="outlined-required"
                                                        select
                                                        sx={{ width: "100%" }}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        value={requestType}
                                                        onChange={(e) => {
                                                            setRequestType(e.target.value)
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.requestType}
                                                        helperText={errors.requestType}
                                                    >
                                                        <option value="UPDATE">
                                                            {t("Declaration.Employees.form.request.UPDATE")}
                                                        </option>
                                                        <option value="DELETE">
                                                            {t("Declaration.Employees.form.request.DELETE")}
                                                        </option>
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                    <TextField
                                                        required
                                                        label={t("Declaration.Employees.form.request.motif")}
                                                        id="outlined-required"
                                                        select
                                                        sx={{ width: "100%" }}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        value={motif}
                                                        onChange={(e) => {
                                                            setMotif(e.target.value)
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.nationality}
                                                        helperText={errors.nationality}
                                                    >
                                                        {motifs && motifs.map((option) => (
                                                            <option key={option.id} value={option?.value}>
                                                                {option?.name}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <Tooltip title={t("Declaration.Employees.form.tooltip.file")} placement="right-start">
                                                        <TextField
                                                            required
                                                            sx={{ width: "100%" }}
                                                            // value={contract}
                                                            onChange={handleFilesChange}
                                                            placeholder=""
                                                            id="outlined-required"
                                                            type="file"
                                                            label={t("Declaration.Employees.form.request.file")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.attachedFiles}
                                                            helperText={errors.attachedFiles}
                                                        // inputProps={{ multiple: true }}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <Tooltip title={t("Declaration.Employees.form.tooltip.description")} placement="left-start">
                                                        <TextField
                                                            required
                                                            sx={{ width: "100%" }}
                                                            value={description}
                                                            onChange={(e) => setDescription(e.target.value)}
                                                            id="outlined-required"
                                                            type="text"
                                                            multiline
                                                            label={t("Declaration.Employees.form.request.description")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.description}
                                                            helperText={errors.description}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Container>
                                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                        <MKBox display="flex" justifyContent="left" p={1.5}>
                                            <MKButton variant="gradient" color="dark" onClick={() => {
                                                showEmployees(user)
                                                setEmploye(null);
                                                setShows(false)
                                            }}>
                                                {t("FNTEC.table.enterprise.close")}
                                            </MKButton>
                                        </MKBox>
                                        <MKBox display="flex" justifyContent="right" p={1.5}>
                                            <MKButton component="a" disabled={loadR} variant="contained" color="primary" onClick={handleSubmitRequest}>
                                                {loadR ? t("Declaration.Employees.addloading") : t("Declaration.Employees.addRequest")}
                                            </MKButton>
                                        </MKBox>
                                    </MKBox>
                                </MKBox>
                            </Slide>
                        </Modal>

                        <Modal open={showu} onClose={() => {
                            setEmploye(null)
                            setShowu(false)
                        }} sx={{ display: "grid", placeItems: "center" }}>
                            <Slide direction="down" in={showu} timeout={500}>
                                <MKBox
                                    position="relative"
                                    width="80%"
                                    display="flex"
                                    flexDirection="column"
                                    borderRadius="xl"
                                    bgColor="white"
                                    shadow="xl"
                                >
                                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                        <MKTypography variant="h5">{t("Declaration.Employees.updateRequest")} {employe && (employe?.fullName?.toUpperCase() || employe?.firstName?.toUpperCase() + " " + employe?.lastName?.toUpperCase())}</MKTypography>
                                        <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => {
                                            setEmploye(null)
                                            setShowu(false)
                                        }}>close</Icon>
                                    </MKBox>
                                    <Divider sx={{ my: 0 }} />
                                    <Container component="section" py={{ xs: 3 }} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                                        <form onSubmit={handleSubmitUpdate}>
                                            {errorFiles && <MKTypography
                                                variant="subtitle2"
                                                color="black"
                                                style={{ background: "yellow", mt: 2 }}
                                            >
                                                {errorFiles}
                                            </MKTypography>}
                                            <Grid container spacing={2} mt={2}> {/* container pour gérer l'espacement */}
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={noms}
                                                        onChange={(e) => setNoms(e.target.value)}
                                                        type="text"
                                                        placeholder="John"
                                                        id="outlined-required"
                                                        label={t("Declaration.Employees.form.noms")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.noms}
                                                        helperText={errors.noms}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={prenoms}
                                                        onChange={(e) => setPrenoms(e.target.value)}
                                                        placeholder="Doe"
                                                        id="outlined-required"
                                                        type="text"
                                                        label={t("Declaration.Employees.form.prenoms")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.prenoms}
                                                        helperText={errors.prenoms}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={birthDate}
                                                        onChange={(e) => setBirthDate(e.target.value)}
                                                        placeholder="Doe"
                                                        id="outlined-required"
                                                        type="date"
                                                        label={t("Declaration.Employees.form.birthDate")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.birthDate}
                                                        helperText={errors.birthDate}
                                                    />
                                                </Grid>
                                                {!loadingC &&
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            required
                                                            label={t("Declaration.Employees.form.nationality")}
                                                            id="outlined-required"
                                                            select
                                                            sx={{ width: "100%" }}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={nationality}
                                                            onChange={(e) => {
                                                                setNationality(e.target.value)
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.nationality}
                                                            helperText={errors.nationality}
                                                        >
                                                            {countries?.data && countries?.data?.map((option) => (
                                                                <option key={option.id} value={i18next?.language == "fr" ? option?.nameFr : option?.nameEn}>
                                                                    {i18next?.language == "fr" ? option?.nameFr : option?.nameEn}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>}
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        label={t("Declaration.Employees.form.sexe")}
                                                        id="outlined-required"
                                                        select
                                                        sx={{ width: "100%" }}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        value={sexe}
                                                        onChange={(e) => {
                                                            setSexe(e.target.value)
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.sexe}
                                                        helperText={errors.sexe}
                                                    // InputProps={{
                                                    //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                                    // }}
                                                    >
                                                        <option key={t("M")} value={"M"}>
                                                            {t("Masculin")}
                                                        </option>
                                                        <option key={t("F")} value={"F"}>
                                                            {t("Feminin")}
                                                        </option>
                                                        {/* {departements?.data && departements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))} */}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={phoneEmp}
                                                        onChange={(e) => setPhoneEmp(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        label={t("Declaration.Employees.form.phone")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.phoneEmp}
                                                        helperText={errors.phoneEmp}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={emailEmp}
                                                        onChange={(e) => setEmailEmp(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="email"
                                                        label={t("Declaration.Employees.form.email")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.emailEmp}
                                                        helperText={errors.emailEmp}
                                                    />
                                                </Grid>
                                                {!loadingP &&
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            required
                                                            label={t("Declaration.Employees.form.profession")}
                                                            id="outlined-required"
                                                            select
                                                            sx={{ width: "100%" }}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={qualification}
                                                            onChange={(e) => {
                                                                setQualification(e.target.value)
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.qualification}
                                                            helperText={errors.qualification}
                                                        // InputProps={{
                                                        //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                                        // }}
                                                        >
                                                            <option>
                                                            </option>
                                                            {occupations?.data && occupations?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                <option key={option.id} value={option.name}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>}
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={passeport}
                                                        onChange={(e) => setPasseport(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="text"
                                                        label={t("Declaration.Employees.form.passeport")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.passeport}
                                                        helperText={errors.passeport}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={visa}
                                                        onChange={(e) => setVisa(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="text"
                                                        label={t("Declaration.Employees.form.visa")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.visa}
                                                        helperText={errors.visa}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                    <TextField
                                                        required
                                                        label={t("Declaration.Employees.form.request.motif")}
                                                        id="outlined-required"
                                                        select
                                                        sx={{ width: "100%" }}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        value={motif}
                                                        onChange={(e) => {
                                                            setMotif(e.target.value)
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.motif}
                                                        helperText={errors.motif}
                                                    >
                                                        <option></option>
                                                        {motifs && motifs.map((option) => (
                                                            <option key={option.id} value={option?.value}>
                                                                {option?.name}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <Tooltip title={t("Declaration.Employees.form.tooltip.file")} placement="left-start">
                                                        <TextField
                                                            required
                                                            sx={{ width: "100%" }}
                                                            // value={contract}
                                                            onChange={handleFilesChange}
                                                            placeholder=""
                                                            id="outlined-required"
                                                            type="file"
                                                            label={t("Declaration.Employees.form.request.file")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.attachedFiles}
                                                            helperText={errors.attachedFiles}
                                                            inputProps={{ multiple: true }}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Container>
                                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                        <MKBox display="flex" justifyContent="left" p={1.5}>
                                            <MKButton variant="gradient" color="dark" onClick={() => {
                                                showEmployees(user)
                                                setEmploye(null);
                                                setShowu(false)
                                            }}>
                                                {t("FNTEC.table.enterprise.close")}
                                            </MKButton>
                                        </MKBox>
                                        <MKBox display="flex" justifyContent="right" p={1.5}>
                                            <MKButton disabled={loadU} variant="contained" color="primary" onClick={handleSubmitUpdate}>
                                                {loadU ? t("Declaration.Employees.updateloading") : t("Declaration.Employees.updateRequest")}
                                            </MKButton>
                                        </MKBox>
                                    </MKBox>
                                </MKBox>
                            </Slide>
                        </Modal>

                        <Modal open={showl} onClose={() => {
                            setEmploye(null)
                            setShowl(false)
                        }} sx={{ display: "grid", placeItems: "center" }}>
                            <Slide direction="down" in={showl} timeout={500}>
                                <MKBox
                                    position="relative"
                                    width="80%"
                                    display="flex"
                                    flexDirection="column"
                                    borderRadius="xl"
                                    bgColor="white"
                                    shadow="xl"
                                >
                                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                        <MKTypography variant="h5">{t("Declaration.Employees.listpermis")} {employe && (employe?.fullName?.toUpperCase() || employe?.firstName?.toUpperCase() + " " + employe?.lastName?.toUpperCase())}</MKTypography>
                                        <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => {
                                            setEmploye(null)
                                            setShowl(false)
                                        }}>close</Icon>
                                    </MKBox>
                                    <Divider sx={{ my: 0 }} />
                                    <Container component="section" py={{ xs: 3 }} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                                        {employe &&
                                            <Grid item sx={{ ml: 2 }}>
                                                <MKTypography variant="h6" mb={1} mt={2}>
                                                    <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.employes.nationality")}:</span> {employe?.nationality}
                                                </MKTypography>
                                                <MKTypography variant="h6" mb={1}>
                                                    <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.employes.sexe")}:</span> {employe?.sexe}
                                                </MKTypography>
                                                <MKTypography variant="h6" mb={1}>
                                                    <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.employes.poste")}:</span> {employe?.occupationName}
                                                </MKTypography>
                                            </Grid>}
                                        <MKBox component="section" py={{ xs: 3 }} sx={{ overflow: 'auto' }}>
                                            <Table responsive bordered hover size="sm" className="custom-table">
                                                <thead>
                                                    <tr style={{ fontSize: "14px" }}>
                                                        <th className="text-center" style={{
                                                            color: "#0F056B",
                                                            textTransform: "uppercase"
                                                        }}>#</th>
                                                        {/* <th className="text-center" style={{
                                                        color: "#0F056B",
                                                        textTransform: "uppercase"
                                                    }}>{t("FNTEC.table.employes.noms")}</th> */}
                                                        <th className="text-center" style={{
                                                            color: "#0F056B",
                                                            textTransform: "uppercase"
                                                        }}>{t("Declaration.Permit.table.salary")}</th>
                                                        <th className="text-center" style={{
                                                            color: "#0F056B",
                                                            textTransform: "uppercase"
                                                        }}>{t("Declaration.Permit.table.numpermis")}</th>
                                                        {/* <th>Entreprise</th> */}
                                                        {/* <th>Salaire</th> */}
                                                        <th className="text-center" style={{
                                                            color: "#0F056B",
                                                            textTransform: "uppercase"
                                                        }}>{t("Declaration.Permit.table.signdate")}</th>
                                                        <th className="text-center" style={{
                                                            color: "#0F056B",
                                                            textTransform: "uppercase"
                                                        }}>{t("Declaration.Permit.table.expdate")}</th>
                                                        {/* <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Permit.table.duree")}</th> */}
                                                        <th className="text-center" style={{
                                                            color: "#0F056B",
                                                            textTransform: "uppercase"
                                                        }}>{t("FNTEC.table.employes.statut")}</th>
                                                        <th className="text-center" style={{
                                                            color: "#0F056B",
                                                            textTransform: "uppercase"
                                                        }}>{t("FNTEC.table.employes.option")}</th>
                                                    </tr>
                                                </thead>
                                                {loadingS && <CircularProgress />}
                                                {!loadingS && <tbody>
                                                    {contracts && contracts.map((item, index) => (
                                                        <tr style={{
                                                            color: "#0F056B",
                                                            verticalAlign: "bottom",
                                                            fontSize: "12px"
                                                        }} key={index}>
                                                            <td className="text-center" style={{ color: "#0F056B" }}>{index + 1}</td>
                                                            {/* <td style={{ color: "#0F056B" }}>{item?.employer?.fullName}</td> */}
                                                            <td className="text-center" style={{ color: "#0F056B" }}>{formatNumber(item?.salary)}</td>
                                                            <td className="text-center" style={{ color: "#0F056B" }}>{item?.permitNumber}</td>
                                                            <td className="text-center" style={{ color: "#0F056B" }}>{dayjs(item?.permitDate).format("DD-MM-YYYY")}</td>
                                                            {/* <td>{item?.enterprise?.socialRaison.toUpperCase()}</td> */}
                                                            {/* <td>{formatNumber(item?.salary)}</td> */}
                                                            <td className="text-center" style={{ color: "#0F056B" }}>{dayjs(getExpiredPermitDate(item?.permitDate)).format("DD-MM-YYYY")}</td>
                                                            {/* <td className="text-center"><Icon style={{ color: "#FF0000" }} fontSize="inherit">thumb_down</Icon></td> */}
                                                            <td className="text-center">{!checkIsValidContractDate(item?.permitDate) ?
                                                                <Icon style={{ color: "#FF0000" }} fontSize="inherit">thumb_down</Icon>
                                                                :
                                                                <Icon style={{ color: "#008000" }} fontSize="inherit">thumb_up</Icon>
                                                            }</td>

                                                            <td className="text-center" style={{ color: "#0F056B" }}>
                                                                <Button color="primary" onClick={() => handleOPenUpdatePermit(item)}>

                                                                    {t("Declaration.Employees.form.request.UPDATE")}
                                                                </Button>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>}
                                            </Table>
                                        </MKBox>
                                    </Container>
                                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                        <MKBox display="flex" justifyContent="right" p={1.5}>
                                            <MKButton variant="gradient" color="dark" onClick={() => {
                                                setEmploye(null);
                                                setShowl(false)
                                            }}>
                                                {t("FNTEC.table.enterprise.close")}
                                            </MKButton>
                                        </MKBox>
                                    </MKBox>
                                </MKBox>
                            </Slide>
                        </Modal>
                        <Modal open={showc} onClose={() => {
                            setEmploye(null)
                            setShowc(false)
                        }} sx={{ display: "grid", placeItems: "center" }}>
                            <Slide direction="down" in={showc} timeout={500}>
                                <MKBox
                                    position="relative"
                                    width="80%"
                                    display="flex"
                                    flexDirection="column"
                                    borderRadius="xl"
                                    bgColor="white"
                                    shadow="xl"
                                >
                                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                        <MKTypography variant="h5">{t("Declaration.Permit.addTitle")}  {employe && (employe?.fullName?.toUpperCase() || employe?.firstName?.toUpperCase() + " " + employe?.lastName?.toUpperCase())}</MKTypography>
                                        <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => {
                                            setEmploye(null)
                                            setShowc(false)
                                        }}>close</Icon>
                                    </MKBox>
                                    <Divider sx={{ my: 0 }} />
                                    <Container component="section" py={{ xs: 3 }} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                                        <form onSubmit={handleSubmitPermit}>
                                            {errorFile && <MKTypography
                                                variant="subtitle2"
                                                color="black"
                                                style={{ background: "yellow", mt: 2 }}
                                            >
                                                {errorFile}
                                            </MKTypography>}
                                            <Grid container spacing={2} mt={2}>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        // value={contract}
                                                        onChange={handleFileChange}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="file"
                                                        label={t("Declaration.Permit.table.contract")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.contract}
                                                        helperText={errors.contract}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={permit}
                                                        onChange={(e) => setPermit(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="text"
                                                        label={t("Declaration.Permit.table.numpermis")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.permit}
                                                        helperText={errors.permit}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={signdate}
                                                        onChange={(e) => setDate(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="date"
                                                        label={t("Declaration.Permit.table.signdate")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.signdate}
                                                        helperText={errors.signdate}
                                                    />
                                                </Grid>
                                                {!loadingP &&
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            required
                                                            label={t("Declaration.Employees.form.profession")}
                                                            id="outlined-required"
                                                            select
                                                            sx={{ width: "100%" }}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={profession}
                                                            onChange={(e) => {
                                                                setProfession(e.target.value)
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.profession}
                                                            helperText={errors.profession}
                                                        // InputProps={{
                                                        //     sx: { height: '50px' }, // Ajustez la hauteur ici
                                                        // }}
                                                        >
                                                            <option>
                                                            </option>
                                                            {occupations?.data && occupations?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                <option key={option.id} value={option.name}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>}
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        required
                                                        sx={{ width: "100%" }}
                                                        value={salary}
                                                        onChange={(e) => setSalary(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.salary")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.salary}
                                                        helperText={errors.salary}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indLogement}
                                                        onChange={(e) => setIndLogement(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.indLogement")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indLogement}
                                                        helperText={errors.indLogement}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indTransport}
                                                        onChange={(e) => setIndTransport(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.indTransport")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indTransport}
                                                        helperText={errors.indTransport}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indResponsabilite}
                                                        onChange={(e) => setIndResponsabilite(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.indResponsabilite")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indResponsabilite}
                                                        helperText={errors.indResponsabilite}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indRepresentation}
                                                        onChange={(e) => setIndRepresentation(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.indRepresentation")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indRepresentation}
                                                        helperText={errors.indRepresentation}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={primeExploitation}
                                                        onChange={(e) => setPrimeExploitation(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.primeExploitation")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.primeExploitation}
                                                        helperText={errors.primeExploitation}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={sursalaire}
                                                        onChange={(e) => setSursalaire(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.sursalaire")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.sursalaire}
                                                        helperText={errors.sursalaire}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={primeSalissure}
                                                        onChange={(e) => setPrimeSalissure(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.primeSalissure")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.primeSalissure}
                                                        helperText={errors.primeSalissure}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={indCaisse}
                                                        onChange={(e) => setIndCaisse(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.indCaisse")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.indCaisse}
                                                        helperText={errors.indCaisse}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={bonus}
                                                        onChange={(e) => setBonus(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.bonus")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.bonus}
                                                        helperText={errors.bonus}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={primePanier}
                                                        onChange={(e) => setPrimePanier(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.primePanier")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.primePanier}
                                                        helperText={errors.primePanier}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={billetAvion}
                                                        onChange={(e) => setBilletAvion(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.billetAvion")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.billetAvion}
                                                        helperText={errors.billetAvion}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={autresPrimes}
                                                        onChange={(e) => setAutresPrimes(e.target.value)}
                                                        placeholder=""
                                                        id="outlined-required"
                                                        type="number"
                                                        label={t("Declaration.Permit.table.autresPrimes")}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!errors.autresPrimes}
                                                        helperText={errors.autresPrimes}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Container>
                                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                        <MKBox display="flex" justifyContent="right" p={1.5}>
                                            <MKButton variant="contained" disabled={loadC} color="primary" onClick={handleSubmitPermit}>
                                                {loadC ? t("Declaration.Employees.loading") : t("Declaration.Employees.add")}
                                            </MKButton>
                                        </MKBox>
                                        <MKBox display="flex" justifyContent="left" p={1.5}>
                                            <MKButton variant="gradient" color="dark" onClick={() => {
                                                setEmploye(null)
                                                setShowc(false)
                                            }}>
                                                {t("FNTEC.table.enterprise.close")}
                                            </MKButton>
                                        </MKBox>
                                    </MKBox>
                                </MKBox>
                            </Slide>
                        </Modal>
                    </Box>
                )}
            </div>
        </>

    );
}

// Ajouter la validation des props
Employees.propTypes = {
    children: PropTypes.string.isRequired, // 'text' doit être une chaîne de caractères
    value: PropTypes.any.isRequired,   // 'value' peut être de n'importe quel type
    index: PropTypes.number.isRequired // 'index' doit être un nombre
};

export default Employees;