import { Box, Card, CardContent, Container, Grid, TextField, Tooltip } from "@mui/material";
// import { useAuth } from "AuthProvider";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { config } from "constants/config/config";
import useDataFetching from "hooks/useDataFetching";
import usePostWithFile from "hooks/usePostWithFile";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Enterprise({ children, value, index, ...other }) {
    const { t } = useTranslation();
    const [region, setRegion] = useState("*");
    const [socialRaison, setSocialRaison] = useState(null);
    const [sigle, setSigle] = useState(null);
    const [locality, setLocality] = useState(null);
    const [niu, setNiu] = useState(null);
    const [rhname, setRhname] = useState("");
    const [rhphone, setRhphone] = useState("");
    const [employerNumber, setEmployernumber] = useState("");
    const [errors, setErrors] = useState({});

    const [editionMode, setEditionMode] = useState(false);

    // const { user } = useAuth();
    const { user } = useSelector((state) => state.auth);

    // FETCH ENTERPRISE LIST
    const [loadingE, errorE, enterprise, fetchEnterprise] = useDataFetching(
        `${config.app.api_url}/enterprises/${user?.myEnterprise?.id}`
    );

    console.log(children, user)
    const [postDataWithFileR, load, error, data] = usePostWithFile(`${config.app.api_url}/user-requests/enterprises`);

    // FETCH REGIONS LIST
    const [loadingR, errorR, regions, fetchRegions] = useDataFetching(
        `${config.app.api_url}/regions`
    );
    const [departement, setDepartement] = useState(null);
    const [arrondissement, setArrondissement] = useState(null);

    // FETCH departments by region id
    const [loadingD, errorD, departements] = useDataFetching(
        `${config.app.api_url}/regions/${region !== "*" && region}/departments`
    );

    // FETCH arrondisements by department id
    const [loadingA, errorA, arrondisements] = useDataFetching(
        `${config.app.api_url}/departments/${departement}/arrondissements`
    );

    useEffect(() => {
        fetchEnterprise();
    }, [user])

    const [motif, setMotif] = useState("");
    // const [description, setDescription] = useState("");
    // const [requestType, setRequestType] = useState("");
    const [attachedFiles, setAttachedFiles] = useState([]);


    const [errorFiles, setErrorFiles] = useState("");



    const handleFilesChange = (e) => {
        const files = e.target.files;
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];
        let validFiles = [];
        let errors = [];

        if (files.length > 0) {
            // Parcourir tous les fichiers sélectionnés
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                // Vérifier le type du fichier
                if (!allowedTypes.includes(file.type)) {
                    errors.push(t("Register.errors.pdf")); // Ajouter un message d'erreur pour le type
                }
                // Vérifier la taille du fichier
                else if (file.size > maxSize) {
                    errors.push(t("Register.errors.file")); // Ajouter un message d'erreur pour la taille
                }
                // Si le fichier est valide
                else {
                    validFiles.push(file); // Ajouter le fichier valide à la liste
                }
            }

            if (errors.length > 0) {
                e.target.value = null; // Réinitialiser le champ de fichier si erreurs
                setErrorFiles(errors.join(", ")); // Afficher les messages d'erreur
            } else {
                setErrorFiles(""); // Réinitialiser les erreurs si tout est correct
                console.log("Valid Files: ", validFiles);
                setAttachedFiles(validFiles); // Enregistrer les fichiers valides dans l'état
            }
        }
    };

    useEffect(() => {
        if (enterprise) {
            console.log("ENTERER ", enterprise)
            setSocialRaison(enterprise?.data?.socialRaison);
            setNiu(enterprise?.data?.nui);
            setSigle(enterprise?.data?.sigle);
            setEmployernumber(enterprise?.data?.employersNumber);
            setLocality(enterprise?.data?.locality);
            setRhname(enterprise?.data?.rhname);
            setRhphone(enterprise?.data?.rhphone);
            setRegion(enterprise?.data?.region?.id);
            setDepartement(enterprise?.data?.department?.id);
            setArrondissement(enterprise?.data?.arrondissement?.id);
        }
    }, [enterprise]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!motif) newErrors.motif = t("Profile.Enterprise.errors.motif");
        // if (!region) newErrors.region = t("Profile.Enterprise.errors.region");
        // if (!departement) newErrors.departement = t("Profile.Enterprise.errors.departement");
        // if (!arrondissement) newErrors.arrondissement = t("Profile.Enterprise.errors.arrondissement");
        // if (!rhname) newErrors.rhname = t("Profile.Enterprise.errors.rhname");
        // if (!rhphone) newErrors.rhphone = t("Profile.Enterprise.errors.rhphone");
        // if (!employerNumber) newErrors.employerNumber = t("Profile.Enterprise.errors.employerNumber");
        if (attachedFiles?.length < 1) newErrors.attachedFiles = t("Profile.Enterprise.errors.attachedFiles");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // console.log("🚀 ~ Enterprise ~ attachedFiles:", typeof attachedFiles)

            // Logique à exécuter si tous les champs sont remplis
            const formData = new FormData();

            // formData.append('userId', user?.id || '');
            enterprise?.data?.rhname != rhname && formData.append('rhname', rhname || '');
            enterprise?.data?.rhphone != rhphone && formData.append('rhphone', rhphone || '');
            enterprise?.data?.region?.id != region && formData.append('region', region || '');
            enterprise?.data?.departement?.id != departement && formData.append('department', parseInt(departement) || '');
            enterprise?.data?.arrondissement?.id != arrondissement && formData.append('arrondisement', parseInt(arrondissement) || '');
            enterprise?.data?.employersNumber != employerNumber && formData.append('employersNumber', parseInt(employerNumber) || '');
            enterprise?.data?.nui != niu && formData.append('nui', niu || '');
            formData.append("motif", motif);
            formData.append("description", motif);
            formData.append("requestType", "UPDATE");
            formData.append("userId", user.id);
            formData.append("attachedFiles", attachedFiles);
            attachedFiles.forEach(element => {
                formData.append("attachedFiles", element);
            });
            console.log("🚀 ~ handleSubmit ~ formData:", formData)

            await postDataWithFileR(formData);
            // await postData({ id: user?.myEnterprise?.id, regionId: region, departmentId: departement, arrondisementId: arrondissement, rhname, rhphone, employersNumber: employerNumber });
        }
    };

    console.log("Error R  D A", loadingE, errorE, errorR, errorD, errorA, loadingD, loadingA)

    useEffect(() => {
        fetchRegions();
    }, []);

    useEffect(() => {
        console.log("DATA ==>> ", data)
        if (data && data?.data?.id) {
            toast(data?.message || t("success"))
            fetchEnterprise();
            setEditionMode(false);
        } else {
            toast(data?.message || t("error"))
        }
    }, [data])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Container>
                        <ToastContainer />
                        <Grid className="d-flex justify-content-center">
                            <Card style={{ background: "white", width: "150vh" }}>
                                <CardContent style={{ textAlign: "center" }}>
                                    {error && <MKTypography
                                        variant="subtitle2"
                                        color="white"
                                        style={{ background: "red", mt: 2 }}
                                    >
                                        {error}
                                    </MKTypography>}
                                    <Container>
                                        <Grid className="d-flex justify-content-center">
                                            <form onSubmit={handleSubmit}>
                                                {errorFiles && <MKTypography
                                                    variant="subtitle2"
                                                    color="black"
                                                    style={{ background: "yellow", mt: 2 }}
                                                >
                                                    {errorFiles}
                                                </MKTypography>}
                                                <Grid container spacing={2} mt={2}> {/* container pour gérer l'espacement */}
                                                    {editionMode &&
                                                        <>
                                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                                <TextField
                                                                    required
                                                                    sx={{ width: "100%" }}
                                                                    value={motif}
                                                                    onChange={(e) => setMotif(e.target.value)}
                                                                    placeholder=""
                                                                    id="outlined-required"
                                                                    type="text"
                                                                    label={t("Declaration.Employees.form.request.motif")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.motif}
                                                                    helperText={errors.motif}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                                <Tooltip title={t("Declaration.Employees.form.tooltip.file")} placement="left-start">
                                                                    <TextField
                                                                        // required
                                                                        sx={{ width: "100%" }}
                                                                        // value={contract}
                                                                        onChange={handleFilesChange}
                                                                        placeholder=""
                                                                        id="outlined-required"
                                                                        type="file"
                                                                        label={t("Declaration.Employees.form.request.file")}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={!!errors.attachedFiles}
                                                                        helperText={errors.attachedFiles}
                                                                        inputProps={{ multiple: true }}
                                                                        accept="application/pdf"
                                                                    />
                                                                </Tooltip>
                                                            </Grid>
                                                        </>

                                                    }
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                        <TextField
                                                            sx={{ width: "100%" }}
                                                            value={socialRaison}
                                                            onChange={(e) => setSocialRaison(e.target.value)}
                                                            type="text"
                                                            id="outlined-required"
                                                            label={t("Profile.Enterprise.card.socialRaison")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.socialRaison}
                                                            helperText={errors.socialRaison}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}
                                                              // placeholder={user?.myEnterprise?.rhname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                        <TextField
                                                            // required
                                                            sx={{ width: "100%" }}
                                                            value={sigle}
                                                            onChange={(e) => setSigle(e.target.value)}
                                                            type="text"
                                                            id="outlined-required"
                                                            label={t("Profile.Enterprise.card.sigle")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.sigle}
                                                            helperText={errors.sigle}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}
                                                              // placeholder={user?.myEnterprise?.rhname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                        <TextField
                                                            // required
                                                            sx={{ width: "100%" }}
                                                            value={niu}
                                                            onChange={(e) => setNiu(e.target.value)}
                                                            type="text"
                                                            id="outlined-required"
                                                            label={t("Profile.Enterprise.card.niu")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.niu}
                                                            helperText={errors.niu}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}
                                                              // placeholder={user?.myEnterprise?.rhname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                        <TextField
                                                            // required
                                                            sx={{ width: "100%" }}
                                                            value={locality}
                                                            onChange={(e) => setLocality(e.target.value)}
                                                            type="text"
                                                            id="outlined-required"
                                                            label={t("Profile.Enterprise.card.locality")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.locality}
                                                            helperText={errors.locality}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}
                                                              // placeholder={user?.myEnterprise?.rhname}
                                                        />
                                                    </Grid>
                                                    {!loadingR &&
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id="outlined-select-currency"
                                                                select
                                                                SelectProps={{
                                                                    native: true,
                                                                }}
                                                                label={t("FNTEC.select.2")}
                                                                value={region}
                                                                onChange={(e) => {
                                                                    if (e.target.value == "*") {
                                                                        setArrondissement("*");
                                                                        setDepartement("*");
                                                                    }
                                                                    setRegion(e.target.value)
                                                                }}
                                                                sx={{ width: "100%" }}
                                                                InputLabelProps={{ shrink: true }}
                                                                error={!!errors.region}
                                                                helperText={errors.region}
                                                                // InputProps={{
                                                                //     readOnly: !editionMode,
                                                                //   }} 
                                                                  disabled={!editionMode}
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}
                                                                  
                                                            // placeholder={user?.myEnterprise?.region}
                                                            >
                                                                <option>---</option>
                                                                {regions?.data && regions?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                    <option key={option.id} value={option.id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </TextField>
                                                        </Grid>}
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={departement}
                                                            label={t("FNTEC.select.3")}
                                                            onChange={(e) => {
                                                                if (e.target.value == "*") {
                                                                    setArrondissement("*");
                                                                }
                                                                setDepartement(e.target.value)
                                                            }}
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.departement}
                                                            helperText={errors.departement}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              disabled={!editionMode}
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}

                                                        // placeholder={user?.myEnterprise?.department}
                                                        >
                                                            <option>---</option>
                                                            {departements?.data && departements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            label={t("FNTEC.select.4")}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={arrondissement}
                                                            onChange={(e) => {
                                                                setArrondissement(e.target.value)
                                                            }}
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.arrondissement}
                                                            helperText={errors.arrondissement}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              disabled={!editionMode}
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}

                                                        // placeholder={user?.myEnterprise?.arrondissement}
                                                        >

                                                            <option>---</option>
                                                            {arrondisements?.data && arrondisements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                        <TextField
                                                            // required
                                                            sx={{ width: "100%" }}
                                                            value={rhname}
                                                            onChange={(e) => setRhname(e.target.value)}
                                                            type="text"
                                                            id="outlined-required"
                                                            label={t("Profile.Enterprise.card.rhname")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.rhname}
                                                            helperText={errors.rhname}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}

                                                        // placeholder={user?.myEnterprise?.rhname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            // required
                                                            sx={{ width: "100%" }}
                                                            value={rhphone}
                                                            onChange={(e) => setRhphone(e.target.value)}
                                                            id="outlined-required"
                                                            type="number"
                                                            label={t("Profile.Enterprise.card.rhphone")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.rhphone}
                                                            helperText={errors.rhphone}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}

                                                        // placeholder={user?.myEnterprise?.rhphone}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            // required
                                                            sx={{ width: "100%" }}
                                                            value={employerNumber}
                                                            onChange={(e) => setEmployernumber(e.target.value)}
                                                            id="outlined-required"
                                                            type="number"
                                                            label={t("Profile.Enterprise.card.employerNumber")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.employerNumber}
                                                            helperText={errors.employerNumber}
                                                            InputProps={{
                                                                readOnly: !editionMode,
                                                              }} 
                                                              onKeyUp={()=>editionMode ? null : alert("let enable modification by clicking on the update button")}
                                                        // placeholder={user?.myEnterprise?.employersNumber}
                                                        />
                                                    </Grid>


                                                </Grid>
                                                <div className="d-flex justify-content-center gap-2">

                                                {!editionMode ? <MKButton
                                                    disabled={load}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    component="a"
                                                    onClick={() => setEditionMode(true)}
                                                    sx={{ mt: 5, alignSelf: 'center', height: 'fit-content' }}
                                                >
                                                    {load ? t("Profile.Enterprise.card.loading") : t("Profile.Enterprise.card.update")}
                                                </MKButton> :
                                                <>
                                                 <MKButton
                                                        disabled={load}
                                                        variant="contained"
                                                        color="warning"
                                                        size="large"
                                                        component="a"
                                                        onClick={()=>{setEditionMode(false); fetchEnterprise()}}
                                                        sx={{ mt: 5, alignSelf: 'center', height: 'fit-content' }}
                                                        >
                                                        {load ? t("Profile.Enterprise.card.loading") : t("Profile.Enterprise.card.cancelUpdate")}
                                                    </MKButton>
                                                    <MKButton
                                                        disabled={load}
                                                        variant="contained"
                                                        color="error"
                                                        size="large"
                                                        component="a"
                                                        onClick={handleSubmit}
                                                        sx={{ mt: 5, alignSelf: 'center', height: 'fit-content' }}
                                                        >
                                                        {load ? t("Profile.Enterprise.card.loading") : t("Profile.Enterprise.card.saveUpdate")}
                                                    </MKButton>
                                                        </>
                                                }
                                                </div>

                                            </form>
                                        </Grid>
                                    </Container>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Container>
                </Box>
            )}
        </div>
    );
}

// Ajouter la validation des props
Enterprise.propTypes = {
    children: PropTypes.string.isRequired, // 'text' doit être une chaîne de caractères
    value: PropTypes.any.isRequired,   // 'value' peut être de n'importe quel type
    index: PropTypes.number.isRequired // 'index' doit être un nombre
};

export default Enterprise;