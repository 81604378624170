import { AddCard, DoDisturbOff, FactCheck, People, PeopleOutlineTwoTone, PersonAddDisabled } from '@mui/icons-material';
import { Box, Card, CardContent, CircularProgress, Container, Grid, Typography } from '@mui/material';
// import { useAuth } from 'AuthProvider';
import { config } from 'constants/config/config';
import useDataFetching from 'hooks/useDataFetching';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';


function Stats({ children, value, index, ...other }) {
    const { t } = useTranslation();

    const { user } = useSelector((state) => state.auth);

    const [loading, error, stats, fetch] = useDataFetching(
        `${config.app.api_url}/enterprises/${user?.myEnterprise?.id}/stats-details`
    );
    console.log(children, error)

    useEffect(() => {
        fetch();
    }, [user])

    // eslint-disable-next-line react/prop-types
    const StatsCard = ({ value, title, icon, color }) => {
        return (
            <Grid item xs={4}>

                <Card sx={{ minWidth: 275, height: 100, backgroundColor: color }}>
                    <CardContent className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex flex-column justify-content-between gap-2'>

                            <Typography variant="h5" component="div">
                                {value}
                            </Typography>
                            {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
                            <Typography variant="body2" fontWeight={'bold'} color="text.secondary">
                                {title || "--"}
                            </Typography>
                        </div>
                        <div>
                            {icon}
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <ToastContainer />
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {loading && <CircularProgress />}
                    {
                        stats?.data &&
                        <Container>
                            <Grid container spacing={2}>
                                {/* Première ligne */}
                                <StatsCard
                                    value={stats?.data?.employeesStats?.total}
                                    title={t("Declaration.Stats.employe.total")}
                                    icon={<People fontSize='large' color='primary' />}
                                    color={'rgb(233, 250, 202)'}
                                />
                                <StatsCard
                                    value={stats?.data?.employeesStats?.regular}
                                    title={t("Declaration.Stats.employe.regular")}
                                    icon={<PeopleOutlineTwoTone fontSize='large' color='warning' />}
                                    color={'rgb(172, 248, 184)'}
                                />
                                <StatsCard
                                    value={stats?.data?.employeesStats?.irregular}
                                    title={t("Declaration.Stats.employe.irregular")}
                                    icon={<PersonAddDisabled fontSize='large' color='error' />}
                                    color={'rgb(248, 172, 197)'}
                                />

                                <StatsCard
                                    value={stats?.data?.contractsStats?.total}
                                    title={t("Declaration.Stats.contract.total")}
                                    icon={<AddCard fontSize='large' color='info' />}
                                    color={'rgb(172, 220, 248)'}
                                />

                                <StatsCard
                                    value={stats?.data?.contractsStats?.regular}
                                    title={t("Declaration.Stats.contract.regular")}
                                    icon={<FactCheck fontSize='large' color='success' />}
                                    color={'rgb(172, 248, 229)'}
                                />

<StatsCard
                                    value={stats?.data?.contractsStats?.irregular}
                                    title={t("Declaration.Stats.contract.irregular")}
                                    icon={<DoDisturbOff fontSize='large' color='error' />}
                                    color={'rgb(248, 222, 172)'}
                                />

                              
                            </Grid>
                        </Container>
                    }
                </Box>
            )}
        </div>
    )

}

// Ajouter la validation des props
Stats.propTypes = {
    children: PropTypes.string.isRequired, // 'text' doit être une chaîne de caractères
    value: PropTypes.any.isRequired,   // 'value' peut être de n'importe quel type
    index: PropTypes.number.isRequired // 'index' doit être un nombre
};

export default Stats;