import {  Card, CardContent, Container, Grid} from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "routes";
// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import minefop from "assets/images/minefop.jpg";
function ContactUs() {
    const { t } = useTranslation();


  

    return (
        <MKBox sx={{ background: "#fff" }}>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left", mt: 10 }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Contact.title")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Container>
                <Grid className="d-flex justify-content-center">
                    <img
                        className="d-block w-20"
                        src={minefop}
                        style={{ height: '30vh' }}
                    />
                </Grid>
                <Grid className="d-flex justify-content-center">
                    <Card style={{ background: "rgba(8, 56, 4, 0.72)", width: "150vh", height: "50vh", marginTop: "5vh" }}>
                        <CardContent style={{ textAlign: "center" }}>
                            <MKTypography
                                variant="h5"
                                color="white"
                            >
                                {t("Contact.card.title")}
                            </MKTypography>
                            <MKTypography
                                variant="h5"
                                color="white"
                            >
                                {t("***********")}
                            </MKTypography>
                          
                       
                            <Container>
                           
                            </Container>
                        </CardContent>
                    </Card>
                  
                </Grid>
            </Container>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </MKBox>
    )
}

export default ContactUs;