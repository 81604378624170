/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
// import { useAuth } from 'AuthProvider';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { config } from 'constants/config/config';
import { Box, CircularProgress, Container, Grid, Table, TableContainer } from '@mui/material';
import MKTypography from 'components/MKTypography';
import { useSelector } from 'react-redux';

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// eslint-disable-next-line react/prop-types
export default function EmployeeRequest({open, onClose, employer}) {


  const handleClose = () => {
    onClose();
  };
  const { t } = useTranslation();
//   const { user, token } = useAuth();
  

  const { user, token } = useSelector((state) => state.auth);
  const [requests, setRequests] = useState({});
  const [loading, setLoading] = useState(false);

  const status = {
      "PENDING": t("Declaration.Request.PENDING"),
      "COMPLETED": t("Declaration.Request.COMPLETED"),
      "REJECTED": t("Declaration.Request.REJECTED"),
  }
  
  const scopes = {
      "ENTERPRISES": t("FNTEC.enterprises"),
      "EMPLOYEES": t("FNTEC.employes"),
      "CONTRACTS": t("FNTEC.contracts"),
  }

  const types = {
      "DELETE": t("Declaration.Request.DELETE"),
      "UPDATE": t("Declaration.Request.UPDATE"),
      "CREATE": t("Declaration.Request.CREATE"),
  }

  useEffect(() => {
      if (user) {
          showRequests(user);
      }
  }, [user]);

  //FETCH EMPLOYEES BY ENTERPRISE ID
  const showRequests = (user) => {
      if (!user) return
      setLoading(true);
      setRequests([]);
      axios
          // eslint-disable-next-line react/prop-types
          .get(`${config.app.api_url}/employers/${employer.id}/requests`,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          )
          .then((res) => {
              console.log("REQUESTS ", res);
              setRequests(res?.data?.data);
          })
          .catch((err) => {
              console.log("REQUESTS ERR ", err);
              setRequests([]);
          })
          .finally(() => {
              setLoading(false)
          });
  };


  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
      >
        <DialogTitle>{"Liste des requetês"}</DialogTitle>
        <DialogContent>
        <Box sx={{ p: 3 }}>
                    <Container>
                        <Grid
                            container
                            item
                            xs={7}
                            lg={12}
                            md={7}
                            justifyContent="space-between" // Gérer l'espacement entre le texte et le bouton
                            alignItems="flex-end" // Aligner le bouton au fond (bas) à droite
                        >
                            <MKTypography
                                variant="h3"
                                color="black"
                                
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    '::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '50%',
                                        height: '3px',
                                        backgroundColor: '#FCE410',
                                        transform: 'translateX(-200%)',
                                    },
                                }}
                                mr={5}
                                mb={3}
                            >
                                {t("Declaration.Employees.form.request.requestList")} : {employer?.fullName || (employer?.firstName +" "+employer?.lastName)} ({!loading && requests ? requests?.EMPLOYEE?.content.length+ requests?.CONTRACT?.content.length : 0})
                            </MKTypography>
                        </Grid>

                    </Container>
                    <TableContainer sx={{mb : 3}}>
                      
                        <h6>{scopes.EMPLOYEES}</h6>
                        <Table sx={{ mb: 3 }} responsive striped bordered hover size="sm" variant="light" className="custom-table">
                            <thead>
                                <tr style={{ fontSize: "14px" }}>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>#</th>
                                                                        <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.noms")}</th>
                                   
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.motif")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.type")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.statut")}</th>
                                </tr>
                            </thead>
                            {loading && <CircularProgress />}
                         
                            {!loading && <tbody>
                                {requests?.EMPLOYEE && requests?.EMPLOYEE?.content.map((item, index) => (
                                    <tr style={{
                                        color: "#0F056B",
                                        verticalAlign: "bottom",
                                        fontSize: "12px"
                                    }} key={index}>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{index + 1}</td>
                                        <td style={{ color: "#0F056B" }}>{item?.employer?.fullName || item?.employer?.firstName +" "+item?.employer?.lastName}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{item?.motif}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{types[item?.requestType]}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{status[item?.status]}</td>
                                    </tr>
                                ))}
                            </tbody>}
                          
                        </Table>
                        <h6>{scopes.CONTRACTS}</h6>
                        <Table sx={{ mb: 3 }} responsive striped bordered hover size="sm" variant="light" className="custom-table">
                            <thead>
                                <tr style={{ fontSize: "14px" }}>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>#</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.noms")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.motif")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.type")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.statut")}</th>
                                </tr>
                            </thead>
                            {loading && <CircularProgress />}
                            
                            {!loading && <tbody>
                                {requests?.CONTRACT && requests?.CONTRACT?.content.map((item, index) => (
                                    <tr style={{
                                        color: "#0F056B",
                                        verticalAlign: "bottom",
                                        fontSize: "12px"
                                    }} key={index}>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{index + 1}</td>
                                        <td style={{ color: "#0F056B" }}>{item?.employer?.fullName || item?.employer?.firstName +" "+item?.employer?.lastName}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{item?.motif}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{types[item?.requestType]}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{status[item?.status]}</td>
                                    </tr>
                                ))}
                            </tbody>}
                        </Table>
                    </TableContainer>
                </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
