/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import MKBadge from "components/MKBadge";
import useDataFetching from "hooks/useDataFetching";
import { config } from "constants/config/config";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Icon } from "@mui/material";
import MKAvatar from "components/MKAvatar";
import axios from "axios";


function Statistiques() {
    const { t } = useTranslation();
    const [region, setRegion] = useState("*");
    const [statsEnterprises, setStatEnterprises] = useState({});
    const [expatsByNationality, setExpatsByNationality] = useState([]);
    const [statsExpats, setStatExpats] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingE, setLoadingE] = useState(false);
    const [loadingEN, setLoadingEN] = useState(false);
    const [loadingES, setLoadingES] = useState(false);
    const [loadingER, setLoadingER] = useState(false);
    const [loadingENR, setLoadingENR] = useState(false);
    const [enterpriseStatBySector, setEnterpriseStatBySector] = useState([]);
    const [expatRegions, setExpatRegions] = useState([]);
    const [enterprisesRegions, setEnterprisesRegions] = useState([]);

    // FETCH REGIONS LIST
    const [loadingR, errorR, regions, fetchRegions] = useDataFetching(
        `${config.app.api_url}/regions`
    );

    const [departement, setDepartement] = useState(null);
    const [arrondissement, setArrondissement] = useState(null);

    // FETCH departments by region id
    const [loadingD, errorD, departements] = useDataFetching(
        `${config.app.api_url}/regions/${region !== "*" && region}/departments`
    );

    // FETCH arrondisements by department id
    const [loadingA, errorA, arrondisements] = useDataFetching(
        `${config.app.api_url}/departments/${departement}/arrondissements`
    );

    console.log("error R ", errorR, errorD, errorA, arrondissement, statsEnterprises, statsExpats, expatsByNationality, enterpriseStatBySector, loadingES)

    const fetchStatEnterprises = () => {
        const params = {};
        if (region != "*") {
            params.regionId = region;
        }
        if (departement != null) {
            params.departmentId = departement;
        }
        if (arrondissement != null) {
            params.arrondissementId = arrondissement;
        }
        params.fromArchive = false;
        setLoading(true);
        axios
            .get(`${config.app.api_url}/enterprises/stats-details`, { params },
                {
                    headers: {
                        // Authorization: authHeader(),
                    }
                }
            )
            .then((res) => {
                setStatEnterprises(res?.data?.data);
                console.log("Enterprises DATA ", res?.data?.data);
            })
            .catch((err) => {
                console.log("Enterprises error ", err);
                setStatEnterprises([]);
            })
            .finally(() => setLoading(false));
    };

    const fetchStatExpats = () => {
        const params = {};
        if (region != "*") {
            params.regionId = region;
        }
        if (departement != null) {
            params.departmentId = departement;
        }
        if (arrondissement != null) {
            params.arrondissementId = arrondissement;
        }
        params.fromArchive = false;
        setLoadingE(true);
        axios
            .get(`${config.app.api_url}/employers/stats-details`, { params },
                {
                    headers: {
                        // Authorization: authHeader(),
                    }
                }
            )
            .then((res) => {
                setStatExpats(res?.data?.data);
                console.log("Enterprises DATA ", res?.data?.data);
            })
            .catch((err) => {
                console.log("Enterprises error ", err);
                setStatExpats({});
            })
            .finally(() => setLoadingE(false));
    };

    const fetchExpatByNationality = () => {
        const params = {};
        if (region != "*") {
            params.regionId = region;
        }
        if (departement != null) {
            params.departmentId = departement;
        }
        if (arrondissement != null) {
            params.arrondissementId = arrondissement;
        }
        params.fromArchive = false;
        setLoadingEN(true);
        axios
            .get(`${config.app.api_url}/employers/stats-by-nationality`, { params },
                {
                    headers: {
                        // Authorization: authHeader(),
                    }
                }
            )
            .then((res) => {
                setExpatsByNationality(res?.data?.data);
                console.log("Enterprises DATA ", res?.data?.data);
            })
            .catch((err) => {
                console.log("Enterprises error ", err);
                setExpatsByNationality([]);
            })
            .finally(() => setLoadingEN(false));
    };

    const fetchEnterpriseBySector = () => {
        const params = {};
        if (region != "*") {
            params.regionId = region;
        }
        if (departement != null) {
            params.departmentId = departement;
        }
        if (arrondissement != null) {
            params.arrondissementId = arrondissement;
        }
        params.fromArchive = false;
        setLoadingES(true);
        axios
            .get(`${config.app.api_url}/enterprises/stats-by-sector`, { params },
                {
                    headers: {
                        // Authorization: authHeader(),
                    }
                }
            )
            .then((res) => {
                setEnterpriseStatBySector(res?.data?.data);
                console.log("Enterprises ES ", res?.data?.data);
            })
            .catch((err) => {
                console.log("Enterprises error ES ", err);
                setEnterpriseStatBySector([]);
            })
            .finally(() => setLoadingES(false));
    };

    const fetchExpatByRegion = () => {
        setLoadingER(true);
        axios
            .get(`${config.app.api_url}/employers/stats-national-regions`,
                {
                    headers: {
                        // Authorization: authHeader(),
                    }
                }
            )
            .then((res) => {
                setExpatRegions(res?.data?.data);
                console.log("Enterprises ER ", res?.data?.data);
            })
            .catch((err) => {
                console.log("Enterprises error ER ", err);
                setExpatRegions([]);
            })
            .finally(() => setLoadingER(false));
    };

    const fetchEnterprisesByRegion = () => {
        setLoadingENR(true);
        axios
            .get(`${config.app.api_url}/enterprises/stats-national-regions`,
                {
                    headers: {
                        // Authorization: authHeader(),
                    }
                }
            )
            .then((res) => {
                setEnterprisesRegions(res?.data?.data);
                console.log("Enterprises ENR ", res?.data?.data);
            })
            .catch((err) => {
                console.log("Enterprises error ENR ", err);
                setEnterprisesRegions([]);
            })
            .finally(() => setLoadingENR(false));
    };

    useEffect(() => {
        fetchRegions();
    }, []);

    useEffect(() => {
        fetchStatEnterprises();
        fetchStatExpats();
        fetchExpatByNationality();
        fetchEnterpriseBySector();
        fetchExpatByRegion();
        fetchEnterprisesByRegion();
    }, [region, departement, arrondissement]);



    //FORMATAGE DES SALAIRES
    const formatNumber = (nombre) => {

        if (nombre != null && nombre != undefined) {
            return nombre.toLocaleString('us')
        }
        else {
            return '--'
        }
    }

    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left" }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Navbar.Services.2")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Grid
                sx={{
                    p: 2,
                    mb: 4,
                }}
            >
                <Container>
                    <Grid>
                        <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                overflowX: 'auto', // Permet le défilement horizontal
                                whiteSpace: 'nowrap', // Empêche les éléments de passer à la ligne suivante
                                '&::-webkit-scrollbar': { display: 'none' }, // Cache la scrollbar sur les navigateurs Webkit
                                msOverflowStyle: 'none',  // Cache la scrollbar sur IE et Edge
                                scrollbarWidth: 'none',  // Cache la scrollbar sur Firefox
                                padding: '5px'
                            }}>
                            <MKBadge sx={{
                                cursor: "pointer", // Ajoute le curseur de pointeur
                                "&:hover": {
                                    cursor: "pointer", // Change le curseur lors du survol
                                    boxShadow: 0, // Ajoute une ombre lors du survol pour un effet visuel
                                },
                                margin: '5px', // Ajoute un espace entre chaque élément
                            }} onClick={() => {
                                setRegion("*");
                                setDepartement(null);
                                setArrondissement(null);
                            }} color={region === "*" ? "success" : "light"} circular={true} badgeContent={"National"} container />
                            {
                                !loadingR && regions?.data ? regions?.data.map((item) => (
                                    <MKBadge sx={{
                                        cursor: "pointer", // Ajoute le curseur de pointeur
                                        "&:hover": {
                                            cursor: "pointer", // Change le curseur lors du survol
                                            boxShadow: 0, // Ajoute une ombre lors du survol pour un effet visuel
                                        },
                                        margin: '5px', // Ajoute un espace entre chaque élément
                                    }} onClick={() => {
                                        setRegion(item?.id)
                                        setDepartement(null);
                                        setArrondissement(null);
                                    }} key={item?.id} color={region === item?.id ? "success" : "light"} circular={true} badgeContent={item?.name} container />
                                )) : <></>
                            }
                        </Grid>
                        {!loadingD && <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                overflowX: 'auto', // Permet le défilement horizontal
                                whiteSpace: 'nowrap', // Empêche les éléments de passer à la ligne suivante
                                '&::-webkit-scrollbar': { display: 'none' }, // Cache la scrollbar sur les navigateurs Webkit
                                msOverflowStyle: 'none',  // Cache la scrollbar sur IE et Edge
                                scrollbarWidth: 'none',  // Cache la scrollbar sur Firefox
                                padding: '5px'
                            }}>
                            {
                                departements?.data && departements?.data.map((item) => (
                                    <MKBadge sx={{
                                        cursor: "pointer", // Ajoute le curseur de pointeur
                                        "&:hover": {
                                            cursor: "pointer", // Change le curseur lors du survol
                                            boxShadow: 0, // Ajoute une ombre lors du survol pour un effet visuel
                                        },
                                        margin: '5px', // Ajoute un espace entre chaque élément
                                    }} onClick={() => {
                                        setDepartement(item?.id);
                                        setArrondissement(null);
                                    }} key={item?.id} color={departement === item?.id ? "success" : "light"} circular={true} badgeContent={item?.name} container />
                                ))
                            }
                        </Grid>}
                        {!loadingA && <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                overflowX: 'auto', // Permet le défilement horizontal
                                whiteSpace: 'nowrap', // Empêche les éléments de passer à la ligne suivante
                                '&::-webkit-scrollbar': { display: 'none' }, // Cache la scrollbar sur les navigateurs Webkit
                                msOverflowStyle: 'none',  // Cache la scrollbar sur IE et Edge
                                scrollbarWidth: 'none',  // Cache la scrollbar sur Firefox
                                padding: '5px'
                            }}>
                            {
                                arrondisements?.data && arrondisements?.data.map((item) => (
                                    <MKBadge sx={{
                                        cursor: "pointer", // Ajoute le curseur de pointeur
                                        "&:hover": {
                                            cursor: "pointer", // Change le curseur lors du survol
                                            boxShadow: 0, // Ajoute une ombre lors du survol pour un effet visuel
                                        },
                                        margin: '5px', // Ajoute un espace entre chaque élément
                                    }} onClick={() => {
                                        setArrondissement(item?.id)
                                    }} key={item?.id} color={arrondissement === item?.id ? "success" : "light"} circular={true} badgeContent={item?.name} container />
                                ))
                            }
                        </Grid>}
                    </Grid>
                    <Grid container xs={12} md={12} spacing={3} justifyContent="left" textAlign="left">
                        <Grid item xs={12} sm={6} lg={8}>
                            <Grid container direction={"row"}>
                                <MKBox
                                    display={{ xs: "block", md: "flex" }}
                                    variant={"contained"}
                                    mr={5}
                                    borderRadius="xl"
                                    sx={{
                                        backgroundColor: 'rgba(8, 56, 4, 0.57)',
                                    }}
                                    pt={2}
                                    px={2}
                                >
                                    {!loading ? <MKAvatar
                                        display="block"
                                        variant="h3"
                                        color={"white"}
                                        sx={{
                                            backgroundColor: 'rgba(242, 153, 74, 0.20)',
                                        }}
                                        textGradient={"contained"}
                                        mt={-0.625}>
                                        <Icon fontSize="lg">account_balance_icon</Icon>
                                    </MKAvatar> : <CircularProgress color="white" size={24} />}
                                    <MKBox pt={{ xs: 3, md: 0 }} pl={{ xs: 0, md: 2 }} lineHeight={1}>
                                        <MKTypography
                                            display="block"
                                            variant="5"
                                            color={"white"}
                                            fontWeight="bold"
                                            mb={1}
                                        >
                                            {statsEnterprises?.total && formatNumber(statsEnterprises?.total)}
                                        </MKTypography>
                                        <MKTypography
                                            display="block"
                                            variant="body2"
                                            color={"white"}
                                            mb={2}
                                        >
                                            {t("Statistiques.enterprise.title")}
                                        </MKTypography>
                                    </MKBox>
                                </MKBox>
                                <MKBox
                                    display={{ xs: "block", md: "flex" }}
                                    variant={"contained"}
                                    borderRadius="xl"
                                    mr={5}
                                    sx={{
                                        backgroundColor: 'rgba(8, 56, 4, 0.57)',
                                    }}
                                    pt={2}
                                    px={2}
                                >
                                    {!loadingE ? <MKAvatar
                                        display="block"
                                        variant="h3"
                                        color={"white"}
                                        sx={{
                                            backgroundColor: 'rgba(242, 153, 74, 0.20)',
                                        }}
                                        textGradient={"contained"}
                                        mt={-0.625}>
                                        <Icon fontSize="lg">people</Icon>
                                    </MKAvatar> : <CircularProgress color="white" size={24} />}
                                    <MKBox pt={{ xs: 3, md: 0 }} pl={{ xs: 0, md: 2 }} lineHeight={1}>
                                        <MKTypography
                                            display="block"
                                            variant="5"
                                            color={"white"}
                                            fontWeight="bold"
                                            mb={1}
                                        >
                                            {statsExpats?.total && formatNumber(statsExpats?.total)}
                                        </MKTypography>
                                        <MKTypography
                                            display="block"
                                            variant="body2"
                                            color={"white"}
                                            mb={2}
                                        >
                                            {t("Statistiques.expatrie.title")}
                                        </MKTypography>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                            <MKBox
                                variant={"contained"}
                                borderRadius="xl"
                                mr={5}
                                mt={3}
                                sx={{
                                    backgroundColor: 'rgba(8, 56, 4, 0.57)',
                                }}
                                pt={2}
                                px={2}
                            >
                                <MKTypography
                                    display="block"
                                    variant="5"
                                    sx={{
                                        color: '#FCE410',
                                    }}
                                    fontWeight="bold"
                                    mb={1}
                                >
                                    {t("Statistiques.repartition.1")}
                                </MKTypography>
                                <Box
                                    sx={{
                                        top: 0,
                                        width: "60%",
                                        bottom: 0,
                                        left: 0, // Positionner la ligne à gauche
                                        borderTop: '1px solid white', // Définit la ligne verticale
                                    }}
                                />
                                {!loadingES ? enterpriseStatBySector.sort((a, b) => b.number - a.number).slice(0, 20).map((item, index) => (
                                    <Grid key={index} container direction="row" mt={2} justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <MKTypography
                                                display="block"
                                                variant="h5"
                                                sx={{
                                                    color: '#E2E2E2',
                                                }}
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                {item?.label == "" ? t("Unknown") : item?.label?.toUpperCase()}
                                            </MKTypography>
                                        </Grid>
                                        <Grid item>
                                            <MKTypography
                                                display="block"
                                                variant="h5"
                                                sx={{
                                                    color: '#FFF',
                                                }}
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                {formatNumber(item?.number)}
                                            </MKTypography>
                                        </Grid>
                                    </Grid>
                                )) : <CircularProgress color="white" size={24} />}
                            </MKBox>
                            {region === "*" ?
                                <MKBox
                                    variant={"contained"}
                                    borderRadius="xl"
                                    mr={5}
                                    mt={3}
                                    sx={{
                                        backgroundColor: 'rgba(8, 56, 4, 0.57)',
                                    }}
                                    pt={2}
                                    px={2}
                                >
                                    <MKTypography
                                        display="block"
                                        variant="5"
                                        sx={{
                                            color: '#FCE410',
                                        }}
                                        fontWeight="bold"
                                        mb={1}
                                    >
                                        {t("Statistiques.repartition.3")}
                                    </MKTypography>
                                    <Box
                                        sx={{
                                            top: 0,
                                            width: "60%",
                                            bottom: 0,
                                            left: 0, // Positionner la ligne à gauche
                                            borderTop: '1px solid white', // Définit la ligne verticale
                                        }}
                                    />
                                    {!loadingER ? expatRegions.sort((a, b) => b.number - a.number).map((item, index) => (<Grid key={index} container direction="row" mt={2} justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <MKTypography
                                                display="block"
                                                variant="h5"
                                                sx={{
                                                    color: '#E2E2E2',
                                                }}
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                {item?.label == "" ? t("Unknown") : item?.label?.toUpperCase()}
                                            </MKTypography>
                                        </Grid>
                                        <Grid item>
                                            <MKTypography
                                                display="block"
                                                variant="h5"
                                                sx={{
                                                    color: '#FFF',
                                                }}
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                {formatNumber(item?.number)}
                                            </MKTypography>
                                        </Grid>
                                    </Grid>
                                    )) : <CircularProgress color="white" size={24} />}
                                </MKBox>
                                : <></>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <MKBox
                                variant={"contained"}
                                borderRadius="xl"
                                mr={5}
                                mt={3}
                                sx={{
                                    backgroundColor: 'rgba(8, 56, 4, 0.57)',
                                }}
                                pt={2}
                                px={2}
                            >
                                <MKTypography
                                    display="block"
                                    variant="5"
                                    sx={{
                                        color: '#FCE410',
                                    }}
                                    fontWeight="bold"
                                    mb={1}
                                >
                                    {t("Statistiques.repartition.4")}
                                </MKTypography>
                                <Box
                                    sx={{
                                        top: 0,
                                        width: "60%",
                                        bottom: 0,
                                        left: 0, // Positionner la ligne à gauche
                                        borderTop: '1px solid white', // Définit la ligne verticale
                                    }}
                                />
                                {!loadingEN ? expatsByNationality.sort((a, b) => b.number - a.number).slice(0, 20).map((item, index) => (
                                    <Grid key={index} container direction="row" mt={2} justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <MKTypography
                                                display="block"
                                                variant="h5"
                                                sx={{
                                                    color: '#E2E2E2',
                                                }}
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                {item?.label == "" || item?.label == undefined ? t("Unknown") : item?.label?.toUpperCase()}
                                            </MKTypography>
                                        </Grid>
                                        <Grid item>
                                            <MKTypography
                                                display="block"
                                                variant="h5"
                                                sx={{
                                                    color: '#FFF',
                                                }}
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                {formatNumber(item?.number)}
                                            </MKTypography>
                                        </Grid>
                                    </Grid>
                                )) : <CircularProgress color="white" size={24} />}
                            </MKBox>
                            {region === "*" ?
                                <MKBox
                                    variant={"contained"}
                                    borderRadius="xl"
                                    mr={5}
                                    mt={3}
                                    sx={{
                                        backgroundColor: 'rgba(8, 56, 4, 0.57)',
                                    }}
                                    pt={2}
                                    px={2}
                                >
                                    <MKTypography
                                        display="block"
                                        variant="5"
                                        sx={{
                                            color: '#FCE410',
                                        }}
                                        fontWeight="bold"
                                        mb={1}
                                    >
                                        {t("Statistiques.repartition.5")}
                                    </MKTypography>
                                    <Box
                                        sx={{
                                            top: 0,
                                            width: "60%",
                                            bottom: 0,
                                            left: 0, // Positionner la ligne à gauche
                                            borderTop: '1px solid white', // Définit la ligne verticale
                                        }}
                                    />
                                    {!loadingENR ? enterprisesRegions.sort((a, b) => b.number - a.number).map((item, index) => (<Grid key={index} container direction="row" mt={2} justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <MKTypography
                                                display="block"
                                                variant="h5"
                                                sx={{
                                                    color: '#E2E2E2',
                                                }}
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                {item?.label == "" ? t("Unknown") : item?.label?.toUpperCase()}
                                            </MKTypography>
                                        </Grid>
                                        <Grid item>
                                            <MKTypography
                                                display="block"
                                                variant="h5"
                                                sx={{
                                                    color: '#FFF',
                                                }}
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                {formatNumber(item?.number)}
                                            </MKTypography>
                                        </Grid>
                                    </Grid>
                                    )) : <CircularProgress color="white" size={24} />}
                                </MKBox>
                                : <></>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Statistiques;
