// import { useAuth } from 'AuthProvider';
import i18n from 'i18n';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const usePostWithFile = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  // const { token } = useAuth();

  const { token } = useSelector((state) => state.auth);
  const postDataWithFile = async (formData) => {
    setLoading(true);
    setError(null);

    try {
      const headers = {'Accept-Language': i18n.language};
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      
      const response = await fetch(url, {
        method: 'POST',
        body: formData, // Utilisation de FormData pour l'envoi du fichier
        headers,
        // Note: Ne pas inclure 'Content-Type', fetch le gérera automatiquement pour FormData
      });
      console.log("response DATA ", response)

      // if (!response.ok) {
      //   throw new Error('Une erreur est survenue lors de la requête.');
      // }

      const result = await response.json();
      setData(result);
    } catch (err) {
      console.log("ERR DATA ", err)
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return [ postDataWithFile, loading, error, data ];
};

export default usePostWithFile;
